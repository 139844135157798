import api from './api';
import { processDepartments } from '../Dtos/companyDepartments';
import { DepartmentResponseDto } from '../types/CompanyDepartments';

export const departmentApis = api.injectEndpoints({
  endpoints: build => ({
    getDepartments: build.query<DepartmentResponseDto, string>({
      query: urlQuery => ({
        url: `company-department${urlQuery}`,
      }),
      providesTags: ['Departments'],
      transformResponse: (response: any) => {
        return processDepartments(response) as DepartmentResponseDto;
      },
    }),
    deleteDepartments: build.mutation<void, { _ids: string }>({
      query: body => ({
        url: `company-department/bulkdelete`,
        method: 'PATCH',
        body,
      }),
      invalidatesTags: ['Departments'],
    }),
    editDepartment: build.mutation<
      void,
      {
        _manager: string;
        name: string;
        _id: string;
      }
    >({
      query: args => ({
        url: `company-department/${args._id}`,
        method: 'PATCH',
        body: {
          name: args.name,
          _manager: args._manager,
        },
      }),
      invalidatesTags: ['Departments'],
    }),
    addDepartment: build.mutation<
      void,
      {
        _manager: string;
        name: string;
      }
    >({
      query: args => ({
        url: `company-department`,
        method: 'POST',
        body: {
          name: args.name,
          _manager: args._manager,
        },
      }),
      invalidatesTags: ['Departments'],
    }),
    validateDepartmentName: build.query<
      {
        exists: boolean;
      },
      string
    >({
      query: name => ({
        url: `company-department/check-name?name=${name}`,
      }),
    }),
  }),
});

export const {
  useGetDepartmentsQuery,
  useDeleteDepartmentsMutation,
  useEditDepartmentMutation,
  useAddDepartmentMutation,
  useLazyValidateDepartmentNameQuery,
} = departmentApis;
