import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { ItemOrder } from 'app/pages/AddRoundTrips/components/ItemOrder';
import { useSearchParams } from 'react-router-dom';
import dayjs, { Dayjs } from 'dayjs';
import './Styles/InfoWindow.css';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import MarkerPinBlue from 'assets/img/map/CustomerMap/MarkerPinBlue.png';
import MarkerPinBlueOutlined from 'assets/img/map/CustomerMap/MarkerPinBlueOutlined.png';
import MarkerPinBlueSelectedHovered from 'assets/img/map/CustomerMap/MarkerPinBlueSelectedHovered.svg';
import MarkerPinUnassigned from 'assets/img/map/CustomerMap/MarkerPinUnassigned.svg';
import MarkerPinGreen from 'assets/img/map/CustomerMap/MarkerPinGreen.png';
import MarkerPinGreenHoveredSelected from 'assets/img/map/CustomerMap/MarkerPinGreenHoveredSelected.png';
import MarkerPinRed from 'assets/img/map/CustomerMap/MarkerPinRed.png';
import MarkerPinRedOutlined from 'assets/img/map/CustomerMap/MarkerPinRedOutlined.png';
import { RoundtripsType } from 'app/pages/RoundTrips/data/types';
import { Icon } from 'app/components/Icon';
import { X, Check } from '@phosphor-icons/react';
import { AdvancedMarker, InfoWindow } from '@vis.gl/react-google-maps';
import { CircleComponent } from './CircleComponent';

interface ClientOrderMarkerProps {
  markerKey: any;
  position: any;
  isSelected?: any;
  type: any;
  clusterer?: any;
  clusterId?: any;
  clientsOrders: any;
  stop?: any;
  index: any;
  onDisplayOrder: any;
  accordionStatus?: any;
  setAccordionStatus?: any;
  roundTripCopy: any;
  requestOrderData?: any;
  orders?: any;
  setOrders?: any;
  setOrderInitCount?: any;
  orderInitCount?: any;
  pinnedLayers?: any;
  setPinnedLayers?: any;
  selectedOrders?: any;
  handleSelectOrders?: any;
  isDragging?: any;
  setAssignedOrders?: any;
  hoveredRtId?: any;
  setMouseOverOrder?: any;
  orderCardRef?: any;
  viewMode?: boolean;
  viewAssignedOrders?: boolean;
  viewUnassignedOrders?: boolean;
  lastSelectByClick?: boolean;
  showOrderStatusActive?: boolean;
  map?: any;
  focusingAtMarkerId?: string;
  circleOptions?: any;
  upKeyToggled?: boolean;
  itemOrderOpenFor?: any;
  handleSetItemOrderOpenFor?: any;
}
export const ClientOrderMarker: React.FC<ClientOrderMarkerProps> = ({
  markerKey,
  position,
  isSelected,
  type,
  clusterer,
  clusterId,
  clientsOrders,
  stop,
  index,
  onDisplayOrder,
  accordionStatus,
  setAccordionStatus,
  roundTripCopy,
  requestOrderData,
  orders,
  setOrders,
  setOrderInitCount,
  orderInitCount,
  pinnedLayers,
  setPinnedLayers,
  selectedOrders,
  handleSelectOrders,
  isDragging,
  setAssignedOrders,
  hoveredRtId,
  setMouseOverOrder,
  orderCardRef,
  viewMode,
  viewAssignedOrders,
  viewUnassignedOrders,
  lastSelectByClick,
  showOrderStatusActive,
  map,
  focusingAtMarkerId,
  circleOptions,
  upKeyToggled,
  itemOrderOpenFor,
  handleSetItemOrderOpenFor,
}) => {
  const [searchParams] = useSearchParams();
  const dateValues = JSON.parse(
    searchParams.get('dateValue') as string,
  ) as Dayjs;
  const dateValue = dayjs(dateValues);

  const handleChange2 = useCallback(
    (id) => {
      const pinnedIds = [...pinnedLayers];

      if (pinnedLayers.includes(id)) {
        const newPinnedIds = pinnedIds.filter((p) => p !== id);
        setPinnedLayers(newPinnedIds);
      } else {
        const newPinnedIds = [...pinnedIds, id];
        setPinnedLayers(newPinnedIds);
      }
    },
    [pinnedLayers],
  );

  const checked = [];

  const [overlayVisible, setOverlayVisible] = useState(false);
  const [mouseOver, setMouseOver] = useState(false);

  const handlePinnedLayers = useMemo(() => {
    return () => {
      let timeoutId;
      if (mouseOver === false && !pinnedLayers?.includes(clientsOrders?._id)) {
        timeoutId = setTimeout(() => {
          setOverlayVisible(false);
        }, 200);
      } else {
        setOverlayVisible(true);
      }

      return () => clearTimeout(timeoutId);
    };
  }, [mouseOver, pinnedLayers, overlayVisible]);

  useEffect(() => {
    handlePinnedLayers();
  }, [handlePinnedLayers]);

  const [selected, setSelected] = useState(false);
  const dragOver = false;

  const handelSelectOrderState = useMemo(() => {
    return () => {
      if (selectedOrders.includes(markerKey)) {
        setSelected(true);
      } else {
        setSelected(false);
        setMouseOver(false);
      }

      if (pinnedLayers?.includes(markerKey)) {
        setOverlayVisible(true);
      } else if (
        selectedOrders[selectedOrders?.length - 1] === markerKey &&
        lastSelectByClick
      ) {
        setOverlayVisible(true);
      } else {
        setOverlayVisible(false);
        setMouseOver(false);
      }
    };
  }, [selectedOrders]);

  useEffect(() => {
    handelSelectOrderState();
  }, [handelSelectOrderState]);

  const isMarkerHighlighted = useMemo(() => {
    return pinnedLayers?.includes(stop?._id) || overlayVisible;
  }, [pinnedLayers, overlayVisible]);

  const notRespecting = useMemo(() => {
    if (clusterer && clientsOrders) {
      const clusterDeliveryDay = new Date(clusterer?.beginAt)
        .toLocaleDateString('en-EN', { weekday: 'long' })
        .toLowerCase();
      const clientDeliveryDays = clientsOrders?._deck?.deliveryDays;

      if (clientDeliveryDays?.includes(clusterDeliveryDay)) {
        return false;
      } else {
        return true;
      }
    }
  }, [clusterer, clientsOrders, orders]);

  const [rtId, setRtId] = useState('');

  const handleSetCurrentroundtripInfo = useMemo(() => {
    if (!roundTripCopy) return;
    return () => {
      roundTripCopy.map((rt) => {
        rt?.stops.map((st) => {
          if (st?._order?._id === clientsOrders?._id) {
            setRtId(rt._id);
          }
        });
      });
    };
  }, [roundTripCopy]);

  useEffect(() => {
    handleSetCurrentroundtripInfo && handleSetCurrentroundtripInfo();
  }, [handleSetCurrentroundtripInfo]);

  const isMarkerSelected = useMemo(() => {
    return (
      isSelected || pinnedLayers?.includes(clientsOrders?._id) || overlayVisible
    );
  }, [
    isSelected,
    pinnedLayers,
    overlayVisible,
    clientsOrders?._id,
    hoveredRtId,
    rtId,
  ]);

  const markerSrc = useMemo(() => {
    const delivered = clientsOrders?.status === 'delivered';
    const canceled =
      clientsOrders?.status === 'canceled' ||
      clientsOrders?.status === 'unloaded';
    const oneSelected = selectedOrders?.length === 1;

    if (dragOver) {
      return MarkerPinBlueOutlined;
    }

    if (!isMarkerSelected) {
      return 'notSelected';
    }

    if (delivered) {
      return isMarkerHighlighted
        ? MarkerPinGreenHoveredSelected
        : MarkerPinGreen;
    }

    if (canceled) {
      return isMarkerHighlighted ? MarkerPinRedOutlined : MarkerPinRed;
    }

    // selected: yes hovered: yes
    if (selected && oneSelected && isMarkerHighlighted) {
      return MarkerPinBlueSelectedHovered;
    }

    // selected: yes hovered: no
    // if (selected && oneSelected) {
    //   return MarkerPinBlueSelected;
    // }

    // // selected: no hovered: yes
    // if (isMarkerHighlighted) {
    //   return MarkerPinBlueHovered;
    // }

    // selected: no hovered: no
    return MarkerPinBlue;
  }, [
    isMarkerSelected,
    notRespecting,
    isMarkerHighlighted,
    dragOver,
    selected,
    selectedOrders,
  ]);

  const [hoveringOverMarker, setHoveringOverMarker] = useState(false);

  useEffect(() => {
    if (upKeyToggled && focusingAtMarkerId === markerKey) {
      setOverlayVisible(true);
      setMouseOver(true);
    } else {
      setOverlayVisible(false);
      setMouseOver(false);
    }
  }, [upKeyToggled, focusingAtMarkerId, markerKey]);

  const orderStatus = useMemo(() => {
    return stop?._order?.status?.toLowerCase();
  }, [stop]);

  // const ref = useCallback(
  //   (marker: google.maps.marker.AdvancedMarkerElement) =>
  //     setMarkerRef(marker, stop?._id),
  //   [setMarkerRef, stop?._id],
  // );

  useEffect(() => {
    if (itemOrderOpenFor !== markerKey) {
      setMouseOver(false);
    } else if (itemOrderOpenFor === markerKey) {
      setTimeout(() => {
        setMouseOver(true);
      }, 300);
    }
  }, [itemOrderOpenFor, markerKey]);

  const handleSingleClick = (e) => {
    // if ctrl => show card + select
    // else only show card
    if (!mouseOver) {
      handleSetItemOrderOpenFor(markerKey);
      handleSelectOrders(e, markerKey, clientsOrders, false);
    } else {
      setMouseOver(false);
      handleSetItemOrderOpenFor('');
    }
  };

  const handleDoubleClick = () => {
    // select stop/order
    return;
  };

  const MarkerComponent = useMemo(() => {
    return type === RoundtripsType.Unassigned ? (
      <AdvancedMarker
        // ref={ref}
        onClick={() => {}}
        clickable={true}
        position={position}
        zIndex={99}
      >
        {selected || !mouseOver ? (
          <MarkerDot
            // draggable={!viewMode && clientsOrders?.status !== 'delivered'}
            // onDragStart={handleDragStart}
            notRespecting={notRespecting}
            id={markerKey}
            onDoubleClickCapture={handleDoubleClick}
            onClick={handleSingleClick}
            selected={selected}
            viewUnassignedOrders={viewUnassignedOrders}
          >
            <div />
          </MarkerDot>
        ) : (
          <MarkerPinContainer stopSelected={true} onClick={handleSingleClick}>
            <div>
              <img
                src="#"
                alt=""
                style={{
                  content: `url(${MarkerPinUnassigned})`,
                }}
                width="22px"
                height="26px"
              />
            </div>
          </MarkerPinContainer>
        )}
      </AdvancedMarker>
    ) : type === RoundtripsType.Assigned && clientsOrders?._id ? (
      <AdvancedMarker
        // ref={ref}
        onClick={() => {}}
        clickable={true}
        position={position}
        zIndex={99}
      >
        <MarkerPinContainer
          id={markerKey}
          stopSelected={selected}
          roundtripSelected={markerSrc !== 'notSelected'}
          viewAssignedOrders={viewAssignedOrders}
          onDoubleClickCapture={handleDoubleClick}
          onClick={handleSingleClick}
        >
          {selected ? (
            <MarkerDot
              // draggable={!viewMode && clientsOrders?.status !== 'delivered'}
              // onDragStart={handleDragStart}
              notRespecting={notRespecting}
              id={markerKey}
              selected={selected}
            >
              <div />
            </MarkerDot>
          ) : markerSrc === 'notSelected' ? (
            <>
              {showOrderStatusActive ? (
                <UnselectedMarker
                  color={
                    orderStatus === 'delivered'
                      ? themes?.default?.green800
                      : orderStatus === 'canceled'
                        ? themes?.default?.redA400
                        : null
                  }
                >
                  {orderStatus === 'delivered' ? (
                    <Icon
                      icon={<Check weight="bold" />}
                      size={8}
                      color={themes?.default?.accordionWhiteBg}
                    />
                  ) : orderStatus === 'canceled' ? (
                    <Icon
                      icon={<X weight="bold" />}
                      size={10}
                      color={themes?.default?.accordionWhiteBg}
                    />
                  ) : null}
                </UnselectedMarker>
              ) : (
                <UnselectedMarker
                  color={
                    hoveredRtId && rtId && hoveredRtId === rtId
                      ? themes?.default?.primaryActiveColor
                      : null
                  }
                />
              )}
            </>
          ) : (
            <div>
              <img
                src="#"
                alt=""
                style={{
                  content: `url(${markerSrc})`,
                }}
                width="22px"
                height="26px"
              />
              <PinNumber>{stop?.pinNumber || stop?.stopNumber}</PinNumber>
            </div>
          )}
        </MarkerPinContainer>
      </AdvancedMarker>
    ) : null;
  }, [
    type,
    position,
    viewMode,
    markerKey,
    notRespecting,
    clientsOrders,
    selected,
    viewUnassignedOrders,
    overlayVisible,
    hoveringOverMarker,
    markerSrc,
    dragOver,
    viewAssignedOrders,
    stop,
    orderStatus,
    showOrderStatusActive,
    mouseOver,
  ]);

  const NavCircle = useMemo(() => {
    if (focusingAtMarkerId !== markerKey) return null;
    return (
      <CircleComponent
        center={position}
        options={{
          ...circleOptions,
          fillColor: 'rgba(248, 251, 109, 0.59)',
          strokeColor: themes?.default?.nobel,
          strokeWeight: 1,
        }}
        map={map}
      />
    );
  }, [focusingAtMarkerId, markerKey, position, circleOptions, map]);

  if (position?.lat && position?.lng)
    return (
      <div
        onMouseEnter={(e) => {
          if (!e.shiftKey) {
            setMouseOverOrder && setMouseOverOrder(clientsOrders);
            setHoveringOverMarker(true);
          }
        }}
        /*onMouseLeave={() => {
          // setMouseOver(false);
          setMouseOverOrder && setMouseOverOrder(null);
          setHoveringOverMarker(false);
        }}*/
      >
        <div /*onMouseLeave={() => setMouseOver(false)}*/>
          {MarkerComponent}
        </div>

        {NavCircle}

        {overlayVisible && !isDragging && (
          <InfoWindow
            position={position}
            pixelOffset={
              type === RoundtripsType.Unassigned ? [0, -28] : [0, -26]
            }
            zIndex={101}
          >
            <div
              onMouseEnter={() => setMouseOver(true)}
              // onMouseLeave={() => setMouseOver(false)}
            >
              <ItemOrder
                ref={orderCardRef}
                dateValue={dateValue}
                handleChange2={handleChange2}
                data={clientsOrders}
                stop={stop}
                isAssigned={type === RoundtripsType.Assigned}
                checked={checked}
                index={index}
                key={clientsOrders?._id}
                onDisplayOrder={onDisplayOrder}
                accordionStatus={accordionStatus}
                setAccordionStatus={setAccordionStatus}
                roundTripCopy={roundTripCopy}
                clusterId={clusterId}
                requestOrderData={requestOrderData}
                orders={orders}
                setOrders={setOrders}
                setOrderInitCount={setOrderInitCount}
                orderInitCount={orderInitCount}
                disableDragAndDrop={true}
                mapVersion={true}
                pinnedLayers={pinnedLayers}
                setAssignedOrders={setAssignedOrders}
                map={map}
              />
            </div>
          </InfoWindow>
        )}
      </div>
    );
  else return null;
};

const MarkerDot = styled.div<{
  selected;
  notRespecting;
  viewUnassignedOrders?: any;
}>`
  height: ${(props) => (props.selected ? '16px' : '20px')};
  width: ${(props) => (props.selected ? '16px' : '20px')};
  opacity: ${(props) =>
    props.viewUnassignedOrders || props.selected ? '1' : '0.4'};
  margin-bottom: -10px;
  border-radius: 20px;
  cursor: pointer;

  display: flex;
  justify-content: center;
  align-items: center;
  background: transparent;
  position: relative;
  transition-duration: 0.1s;
  transition-timing-function: ease-out;

  ${(props) => {
    if (props.selected) {
      return `div {
        height: 16px;
        width: 17px;
        border-radius: 17px;
        background: ${themes?.default?.clientMarkerBgSelected};
        border: 1.5px solid
          ${themes?.default?.clientMarkerBorderSelected};
        outline: 1.5px solid ${themes?.default?.clientMarkerBorderSelected}80;
        box-shadow: 0px 0.75px 7.5px 0px #0000001F;
        box-shadow: 0px 3px 3.75px 0px #00000024;
        box-shadow: 0px 1.5px 3px -0.75px #00000033;
      }`;
    } else if (!props.selected && props.notRespecting) {
      return `div {
        height: 14.5px;
        width: 14.5px;
        border-radius: 14.5px;
        background: ${themes?.default?.clientMarkerBg};
        border: 1.5px solid
          ${themes?.default?.clientMarkerBorder};
        box-shadow: 0px 1px 10px 0px #0000001F;
        box-shadow: 0px 4px 5px 0px #00000024;
        box-shadow: 0px 2px 4px -1px #00000033;
        position: relative;
      }
      &::after {
        content: '';
        height: 9px;
        width: 9px;
        border-radius: 9px;
        border: 1.5px solid ${themes.default.clientMarkerBorder};
        position: absolute;
        top: 0;
        right: 0;
        background: ${themes?.default?.mapPink};
        z-index: 1;
      }
      `;
    } else if (!props.selected && !props.notRespecting) {
      return `div {
        height: 14.5px;
        width: 14.5px;
        border-radius: 14.5px;
        background: ${themes?.default?.clientMarkerBg};
        border: 1.5px solid
          ${themes?.default?.clientMarkerBorder};
        box-shadow: 0px 1px 10px 0px #0000001F;
        box-shadow: 0px 4px 5px 0px #00000024;
        box-shadow: 0px 2px 4px -1px #00000033;
      }`;
    }
  }}
`;

export const MarkerPinContainer = styled.div<{
  stopSelected;
  roundtripSelected?: any;
  viewAssignedOrders?: any;
}>`
  height: ${(props) => (props.roundtripSelected ? '38px' : 'fit-content')};
  width: ${(props) => (props.roundtripSelected ? '30px' : 'fit-content')};
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: ${(props) =>
    props.viewAssignedOrders || props.stopSelected ? '1' : '0.4'};
  cursor: pointer;
  position: relative;
  z-index: ${(props) => (props.stopSelected ? '2' : '1')};
  //   props.stopSelected ? '-7px' : props.roundtripSelected ? '-7px' : '-8px'};
  margin-bottom: ${(props) =>
    !props.stopSelected && !props.roundtripSelected
      ? -7
      : props.stopSelected && !props.roundtripSelected
        ? 0
        : !props.stopSelected && props.roundtripSelected
          ? -7
          : props.stopSelected && props.roundtripSelected
            ? -15
            : 0}px;

  img {
    height: 26px;
    width: 22px;
  }
`;

const UnselectedMarker = styled.div<{ color?: any }>`
  height: 14.5px;
  width: 14.5px;
  border-radius: 14.5px;
  background: ${(props) =>
    props.color ? props.color : themes?.default?.nobel};
  border: 1.5px solid ${themes?.default?.clientMarkerBorder};
  box-shadow: 0px 2px 4px -1px #00000033;
  position: relative;

  svg {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const PinNumber = styled.span`
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -45%);
  font-size: 0.7rem;
  font-weight: 500;
  color: #fff;
`;
