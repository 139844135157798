import { formatDeliveryHours, formatDistance } from 'common/helpers/place';
import { formatDuration } from 'common/utils/dates';
import { isEmpty } from 'lodash';

function getWords(str: string) {
  return ([] as string[]).concat(
    str
      .trim()
      .split(' ')
      .map((w) => w.trim().toLowerCase()),
  );
}

export function filterRoundtripBySearch(
  roundtrips: any[],
  search: string,
  translation?: any,
): any[] {
  if (isEmpty(search) || isEmpty(roundtrips)) {
    return roundtrips;
  }

  const words = getWords(search);

  const filteredRoundtrips = roundtrips?.filter((roundtrip) => {
    const startPlace = roundtrip?._startPlace?.code?.toLowerCase() || '';
    const code = `${roundtrip?.code?.toLowerCase()}~${roundtrip?.internalCode?.toLowerCase()}`;
    const internalCode = roundtrip?.internalCode?.toLowerCase() || '';
    const numberPlate = roundtrip?._vehicle?.numberPlate?.toLowerCase() || '';
    const model = roundtrip?._vehicle?.model?.toLowerCase() || '';
    const vehicle = `${model}/${numberPlate}/${roundtrip?._vehicle?.parkNumber}`;
    let status = roundtrip?.status?.toLowerCase() || '';
    status = (
      translation ? translation(`roundtrips.status.${status}`) : status
    )?.toLowerCase();
    const weight = `${
      roundtrip?.cache?.stats?.totalWeight?.toFixed(2) || 'N/A'
    } kg`;
    const distance = formatDistance(roundtrip.cache?.directions?.distance || 0);
    const duration = formatDuration(
      roundtrip.cache?.directions?.estimatedTimeWithStop || 0,
    );
    let supportUnit = roundtrip?._vehicle
      ? `${
          roundtrip?.cache?.stats?.totalSupportUnitsRequestedCapacity || 0
        } / ${roundtrip?._vehicle?.capacity?.supportUnit} `
      : `${roundtrip?.supportUnitsRequestedCapacity || 0} `;
    supportUnit = (supportUnit || '').trim().replace(/\s/g, ''); // remove spaces

    // Should match with every word in the search param
    return words.every(
      (w) =>
        code?.includes(w) ||
        internalCode?.includes(w) ||
        startPlace?.includes(w) ||
        vehicle?.includes(w) ||
        status?.includes(w) ||
        weight?.includes(w) ||
        distance?.includes(w) ||
        duration?.includes(w) ||
        supportUnit?.includes(w) ||
        roundtrip?.cache?.driverUser?.fullName?.toLowerCase()?.includes(w) ||
        roundtrip?.cache?.ripperUser?.fullName?.toLowerCase()?.includes(w),
    );
  });

  if (filteredRoundtrips.length > 0) {
    return filteredRoundtrips;
  }

  return roundtrips
    .map((roundtrip) => filterRoundtripStops(roundtrip, search, translation))
    .filter((roundtrip) => roundtrip.stops.length > 0);
}

export function filterRoundtripStops(
  roundtrip,
  search: string,
  translation?: any,
) {
  if (isEmpty(search)) {
    return roundtrip;
  }

  const words = getWords(search);

  const stops = roundtrip.stops.filter((stop, index) => {
    const code = stop?._order?.code?.toLowerCase() || '';
    const customerCode = stop?._order?._customer?.code?.toLowerCase() || '';
    const legalName = stop?._order?._customer?.legalName?.toLowerCase() || '';
    const publicName = stop?._order?._customer?.publicName?.toLowerCase() || '';
    const location = stop?._place?.location?.name?.toLowerCase() || '';
    const place = stop?._place?.name?.toLowerCase() || '';
    const timeslots = formatDeliveryHours(
      stop?._place?.deliveryHours || '',
    ).replace(/\s/g, '');
    const totalProducts = stop?._order?.countPackages?.toString() || '';
    const weight = stop?._order?.totalWeight?.toFixed(2) || '';
    const volume = stop._order?.supportUnitsRequestedCapacity?.toString() || '';
    const supportUnits = stop?._order?.supportUnits?.toString();
    let status = (stop?._order ? stop?._order?.status : stop?.status) || '';
    status = (
      translation ? translation(`orders.status.${status}`) : status
    )?.toLowerCase();
    const priority = stop?.priority?.toString() || '';
    const time = stop.estimatedArrivalAt
      ? new Date(stop.estimatedArrivalAt).toLocaleTimeString('fr-FR', {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false,
        })
      : '';

    console.log('abed: stop: ', stop);
    console.log('abed: code: ', code);
    console.log('abed: customerCode: ', customerCode);
    let stopIndexText = '';
    const type = stop.type;
    if (type === 'fuel') {
      stopIndexText = translation('fuel');
    } else if (type === 'maintenance') {
      stopIndexText = translation('maintenance');
    } else {
      stopIndexText = `${translation('common.stop')} ${index + 1}`;
    }
    stopIndexText = stopIndexText.toLowerCase();

    // Should match with every word in the search param
    return words.every(
      (w) =>
        code?.includes(w) ||
        customerCode?.includes(w) ||
        location?.includes(w) ||
        legalName?.includes(w) ||
        publicName?.includes(w) ||
        timeslots?.includes(w) ||
        weight?.includes(w) ||
        volume?.includes(w) ||
        supportUnits?.includes(w) ||
        status?.includes(w) ||
        priority?.includes(w) ||
        time?.includes(w) ||
        stopIndexText?.includes(w) ||
        place?.includes(w) ||
        totalProducts === w,
    );
  });

  return {
    ...roundtrip,
    stops,
  };
}
