import React, { useState, useMemo } from 'react';
import { Modal } from '@mui/material';
import { ModalHeader } from './ModalHeader/index';
import styled from 'styled-components';
import If from './../If/index';
import { themes } from 'styles/theme/themes';

interface ModalProps {
  onClose: () => void;
  onOpen: boolean;
  title: string;
  width?: string;
  height?: number;
  defaultMode?: 'sidebar' | 'floated';
  scrollY?: boolean;
  children: React.ReactNode;
  handleViewChange?: Function;
  showBordersBottom?: boolean;
  headerTitleProps?: any;
  hasArrowBack?: boolean;
  onBackClick?: (e?: any) => void;
}

export const TwoViewModal = ({
  title,
  width = '473px',
  height = 80,
  onClose,
  onOpen,
  defaultMode = 'sidebar',
  scrollY = true,
  children,
  handleViewChange,
  showBordersBottom = true,
  headerTitleProps,
  hasArrowBack = false,
  onBackClick,
}: ModalProps) => {
  const [viewMode, setViewMode] = useState<'sidebar' | 'floated'>(defaultMode);

  const [BodyChild, FooterChild] = React.Children.toArray(children);

  useMemo(() => {
    if (viewMode) {
      handleViewChange && handleViewChange(viewMode);
    }
  }, [viewMode]);

  return (
    <Modal
      open={onOpen}
      onClose={onClose}
      aria-labelledby={title}
      sx={{
        display: 'flex',
        justifyContent: viewMode === 'sidebar' ? 'end' : 'center',
      }}
    >
      <ModalContentWrapper
        onCenter={viewMode === 'floated'}
        width={width}
        height={height}
      >
        <ModalHeaderWrapper showBordersBottom={showBordersBottom}>
          <ModalHeader
            title={title}
            onClose={onClose}
            viewMode={viewMode}
            setViewMode={setViewMode}
            headerTitleProps={headerTitleProps}
            hasArrowBack={hasArrowBack}
            onBackClick={onBackClick}
          />
        </ModalHeaderWrapper>

        {/*  dynamic design acconrding to Modal children */}
        {/*  In case the footer passed to the modal with body */}
        <ModalBodyWrapper
          footerChildrenExists={!!FooterChild}
          scrollY={scrollY}
        >
          {BodyChild}
        </ModalBodyWrapper>

        <If condition={!!FooterChild} otherwise={null}>
          <ModalFooterWrapper>{FooterChild}</ModalFooterWrapper>
        </If>
      </ModalContentWrapper>
    </Modal>
  );
};

const ModalContentWrapper = styled.div<{
  onCenter: boolean;
  width: string;
  height: number;
}>`
  display: flex;
  flex-direction: column;
  height: ${(props) => (props.onCenter ? props.height + 'vh' : '100vh')};
  margin: ${(props) =>
    props.onCenter ? (100 - props.height) / 2 + 'vh 0' : '0'};
  width: ${(props) => props.width};
  border-radius: ${(props) => (props.onCenter ? '5px' : '5px 0 0 0')};
  background-color: ${(props) => props.theme.background};
  transition:
    height 0.3s ease,
    margin 0.3s ease,
    border-radius 0.3s ease,
    justify-content 0.3s ease;
  position: relative;
`;

const ModalHeaderWrapper = styled.div<{
  showBordersBottom: boolean;
}>`
  /* flex: 0 0 10%; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 24px;
  width: 100%;
  height: 45px;
  background-color: ${themes.default.background};
  border-bottom: ${(props) =>
    props.showBordersBottom
      ? '1px solid var(--divider, rgba(0, 0, 0, 0.12))'
      : ''};
`;

const ModalBodyWrapper = styled.div<{
  footerChildrenExists: boolean;
  scrollY: boolean;
}>`
  flex: 1 1 ${(props) => (props.footerChildrenExists ? '80%' : '90%')};
  width: 100%;
  overflow-y: ${(props) => (props.scrollY ? 'scroll' : 'none')};
  padding-bottom: 30px;
`;

const ModalFooterWrapper = styled.div`
  flex: 0 0 10%;
  display: flex;
  align-items: center;
  width: 100%;
  border-top: '1px solid var(--divider, rgba(0, 0, 0, 0.12))';
`;
