import {
  Alert,
  Autocomplete,
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  IconButton,
  TextField,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Dispatch,
  SetStateAction,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import AddIcon from '@mui/icons-material/Add';
import {
  useAddRoundTripsMutation,
  useUpdateRoundTripsClusterMutation,
  useAddStopRoundtripMutation,
  useLazyGetRoundTripsClusterAvailableInternalCodesQuery,
} from 'common/services/roundtripApi';
import {
  AddRoundTrip,
  addInArrRoundrip,
  addInArrVehicle,
  resetData,
  deleteButton,
  addInArrByRole,
} from './functions';
import { roundtripActions } from 'common/store/roundtrips';
import { useDispatch } from 'react-redux';
import { AlertContainer } from 'app/pages/RoundTrips/components/NoData/Components/Modals/Existing';
import { useToaster } from 'hooks/useToaster';
import { useUsersData } from '../../hooks/useUsersData';
import { disabledVehicle } from 'app/components/RoundTrips/function';
import {
  Driver,
  Sales,
} from 'app/components/RoundTrips/RoundTripData/statuses';
import { v4 as uuidv4 } from 'uuid';
import { getVehicleLabel } from 'utils/pages/vehicles';
import { SelectorRenderOption } from '../../data/RoundTripData/components/components/SelectorRenderOption';
import { AgentCategories } from 'app/components/RoundTrips/RoundTripData/agentCategories';
import If from 'app/components/If';
import { TrashSimple } from '@phosphor-icons/react';
import { ResourcesStatusHeader } from './component/ResourcesStatusHeader';
import { selectOrganization } from 'common/store/organization/selectors';
import { useSelector } from 'react-redux';
import { StateSetter } from 'types';
import { TwoViewModal } from 'app/components/TwoViewModal';
import { sortBy } from 'lodash';

interface Props {
  setAnchorElActivate: Dispatch<SetStateAction<boolean>>;
  anchorElActivate: boolean;
  setClusterId: Dispatch<SetStateAction<string>>;
  clusterId: string;
  vehicles: any;
  users: any;
  deck: any;
  dateAdd: string;
  roundTripCode: string[];
  roundTripData: any;
  setData: any;
  data: any;
  mode?: string | null;
  driverAgentId?: string | null;
  emptyApi?: boolean;
  loadingData?: boolean;
  userContext?: AgentCategories;
  salesAgentId?: string | null;
  assignStopsMode?: boolean;
  stopsToAssign?: any[];
  setClearSelectionCount?: StateSetter<number>;
}
export const AddRoundtrip: React.FC<Props> = ({
  setClusterId,
  roundTripData,
  setAnchorElActivate,
  anchorElActivate,
  vehicles,
  users,
  deck,
  dateAdd,
  roundTripCode,
  clusterId,
  setData,
  data,
  mode,
  driverAgentId,
  emptyApi,
  loadingData,
  userContext,
  salesAgentId,
  assignStopsMode,
  stopsToAssign,
  setClearSelectionCount = () => {},
}) => {
  // hooks
  const dispatch = useDispatch();
  const organization = useSelector(selectOrganization);

  const roleAgentId =
    userContext === AgentCategories.Sales ? salesAgentId : driverAgentId;

  const { t } = useTranslation();
  const openActivate = Boolean(anchorElActivate);
  const [addRoundtrip] = useAddRoundTripsMutation();
  const [UpdateRoundtrip] = useUpdateRoundTripsClusterMutation();
  const [addStopRoundtrip] = useAddStopRoundtripMutation();
  const [getAvailableInternalCodes, { data: internalCodesData }] =
    useLazyGetRoundTripsClusterAvailableInternalCodesQuery();

  useEffect(() => {
    getAvailableInternalCodes(clusterId);
  }, [clusterId, getAvailableInternalCodes]);

  const toast = useToaster();
  // variables && states

  const [addingRoundtrips, setAddingRoundtrips] = useState(false);
  const [vehiculeData, setVehiculeData] = useState<any>(vehicles);

  const [usersCopy, setUsersCopy] = useState([...users]);
  const [usersData, setUsersData] = useState<any>(
    usersCopy?.sort((a, b) => {
      return a?.fullName?.localeCompare(b?.fullName);
    }),
  );
  const [tripProps, setTripProps] = useState<any>(roundTripCode);
  const VehiculeProps = useMemo(() => {
    return {
      options: !vehiculeData?.[0]
        ? [{ label: 'Loading...', id: 0 }]
        : vehiculeData.map((option) => ({
            ...option,
            ownerType: option.ownerType,
          })),
      getOptionLabel: (option: any) => getVehicleLabel(option),
      //getOptionDisabled: option => option?.disabled,
      renderOption: (props, option) => {
        return (
          <SelectorRenderOption props={props} type="vehicle" option={option} />
        );
      },
    };
  }, [vehiculeData]);
  const UserProps = useMemo(() => {
    return {
      options: !usersData?.[0] ? [{ label: 'Loading...', id: 0 }] : usersData,
      getOptionLabel: (option: any) =>
        option?.fullName ? option?.fullName : '',
      //getOptionDisabled: option => option?.disabled,
      renderOption: (props, option) => {
        return (
          <SelectorRenderOption props={props} type="user" option={option} />
        );
      },
    };
  }, [usersData]);
  const RoundtripProps = useMemo(() => {
    return {
      options: !tripProps ? [{ label: 'Loading...', id: 0 }] : tripProps,
      getOptionLabel: (option: any) =>
        option?.internalCode ? option?.internalCode : '',
      getOptionDisabled: (option) => option?.disabled,
    };
  }, [tripProps]);

  const addFuction = async () => {
    if (!clusterId) {
      try {
        setAddingRoundtrips(true);
        const modeName = mode
          ? mode.charAt(0)?.toUpperCase() + mode.slice(1)
          : '';

        const resp = await addRoundtrip({
          _deck: deck?._id,
          date: dateAdd,
          name: deck.name + ' - ' + modeName,
          mode: emptyApi ? 'manual' : mode || '',
          roundtrips: data,
          agentCategory: userContext || AgentCategories.Logistic,
        }).unwrap();

        if (resp) {
          toast(5000, 'success', 'alerts.roundtrips.add_success');
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.append('cluster', JSON.stringify(resp.cluster?._id));
          //@ts-ignore
          window.location.search = urlParams;

          setClusterId(resp.cluster?._id);
          handleCloseActivate();
          if (assignStopsMode) {
            await addStopRoundtrip({
              id: resp?.roundtrips[resp?.roundtrips?.length - 1]?._id,
              data: {
                stops: stopsToAssign,
              },
            }).unwrap();
            setClearSelectionCount((prevCount) => prevCount + 1);
            toast(5000, 'success', 'alerts.orders.assign_success');
            toast(5000, 'success', 'alerts.orders.assign_success');
          }
        }
      } catch (e: any) {
        if (e.status !== 400) {
          handleCloseActivate();
        }
      } finally {
        setAddingRoundtrips(false);
      }
    } else {
      try {
        setAddingRoundtrips(true);
        const resp = await UpdateRoundtrip({
          id: clusterId,
          roundtrips: data,
          userKey: userContext === AgentCategories.Sales ? Sales : Driver,
        }).unwrap();
        if (resp) {
          toast(5000, 'success', 'alerts.roundtrips.add_success');
          dispatch(roundtripActions.setClusterId(resp.cluster?._id));
          setClusterId(resp.cluster?._id);
          handleCloseActivate();
        }
        if (assignStopsMode) {
          await addStopRoundtrip({
            id: resp?.roundtrips[resp?.roundtrips?.length - 1]?._id,
            data: {
              stops: stopsToAssign,
            },
          }).unwrap();
          setClearSelectionCount((prevCount) => prevCount + 1);
          toast(5000, 'success', 'alerts.orders.assign_success');
        }
      } catch (e: any) {
        if (e.status !== 400) {
          handleCloseActivate();
        }
      } finally {
        setAddingRoundtrips(false);
      }
    }
  };

  const handleCloseActivate = () => {
    setAnchorElActivate(false);
  };

  useEffect(() => {
    const usedCodes =
      internalCodesData?.usedInternalCodes.map((code) => ({
        internalCode: code,
        disabled: true,
      })) || [];
    const availableCodes =
      internalCodesData?.availableInternalCodes.map((code) => {
        const idx = data?.findIndex((d) => d?.internalCode === code);
        return {
          internalCode: code,
          disabled: idx !== -1,
        };
      }) || [];
    const totalCodes = sortBy(
      availableCodes.concat(usedCodes),
      (code) => code.disabled,
    );
    // roundTripCode.map((t) => {
    //   if (
    //     !!data?.find((d) => d?.internalCode === t) ||
    //     roundTripData?.find((d) => d?.internalCode === t)
    //   ) {
    //     used.push({ internalCode: t, disabled: true });
    //   } else {
    //     available.push({ internalCode: t, disabled: false });
    //   }
    // });
    setTripProps(totalCodes);
  }, [roundTripCode, data, roundTripData, internalCodesData]);

  useUsersData(usersData, roundTripData, usersCopy, data, setUsersData);

  useEffect(() => {
    const newUsers = [...users];
    setUsersCopy(
      newUsers?.sort((a, b) => {
        return a?.fullName?.localeCompare(b?.fullName);
      }),
    );
  }, [users]);

  useEffect(() => {
    disabledVehicle(
      vehiculeData,
      vehicles,
      setVehiculeData,
      data,
      roundTripData,
    );
  }, [vehicles, data, roundTripData]);

  const checkLastCode = (checkData: boolean) => {
    let a = 0;
    let i = 1;
    let code = 0;
    while (a === 0) {
      if (loadingData && (mode === 'automatic' || emptyApi)) {
        break;
      }
      if (
        (checkData &&
          (data?.find((d) => d.code === 'R' + i || d.code === 'R0' + i)?.code ||
            roundTripData?.find(
              (d) => d.code === 'R' + i || d.code === 'R0' + i,
            )?.code)) ||
        (!checkData &&
          roundTripData?.find((d) => d.code === 'R' + i || d.code === 'R0' + i)
            ?.code)
      ) {
        i = i + 1;
      } else {
        code = i;
        a = 1;
        break;
      }
    }
    return code;
  };

  const getLastCode = useCallback(async () => {
    if (roleAgentId) {
      if (!roundTripData?.length) {
        resetData(1, setData, roundTripData, roleAgentId, dateAdd);
        Array.from({ length: 3 }, () => addKeyToArray());
      } else {
        resetData(
          checkLastCode(false),
          setData,
          roundTripData,
          roleAgentId,
          dateAdd,
        );
        addKeyToArray();
      }
    }
    if (openActivate) {
      getAvailableInternalCodes(clusterId);
    }
  }, [openActivate, clusterId]);

  useEffect(() => {
    getLastCode();
  }, [getLastCode]);

  const keyArrayRef = useRef<(string | number)[]>([]);
  const addKeyToArray = () => {
    const newNumber = uuidv4();
    keyArrayRef.current = [...keyArrayRef.current, newNumber];
  };

  const [countRoundtrip, setCountRoundtrip] = useState<number>(0);
  const [countUsers, setCountUsers] = useState<number>(0);
  const [countTrucks, setCountTrucks] = useState<number>(0);

  const removeKeyByIndex = (index) => {
    keyArrayRef.current = keyArrayRef.current.filter((_, i) => i !== index);
  };

  useEffect(() => {
    const nmbRoundtrip = RoundtripProps.options.filter(
      (option) => !option.disabled,
    ).length;
    const nmbUsers = UserProps.options.filter(
      (item) => item.roundtripCode === undefined,
    ).length;
    const nmbTrucks = VehiculeProps.options.filter(
      (option) => !option.disabled,
    ).length;

    setCountRoundtrip(nmbRoundtrip);
    setCountUsers(nmbUsers);
    setCountTrucks(nmbTrucks);
  }, [RoundtripProps, VehiculeProps, UserProps, tripProps?.options]);

  return (
    <TwoViewModal
      onOpen={openActivate}
      onClose={handleCloseActivate}
      title={
        !assignStopsMode
          ? t('roundtrips.edit_roundtrip.add_roundtrip')
          : t('assign_stops_to_new_roundtrip', {
              count: stopsToAssign?.length || 0,
            })
      }
      width="900px"
    >
      <DialogContent
        sx={{
          overflow: 'hidden',
        }}
      >
        <AlertContainer>
          {emptyApi && (
            <Alert variant="standard" severity="error" sx={{ width: '100%' }}>
              {t('drawer.modal.roundtrips_api_empty')}
            </Alert>
          )}
        </AlertContainer>
        <If condition={data.length > 0}>
          <ResourcesStatusHeader
            countRoundtrip={countRoundtrip}
            countDrivers={countUsers}
            countTrucks={countTrucks}
            userContext={userContext}
          />
        </If>
        <div style={{ overflow: 'auto', maxHeight: '170px' }}>
          {data &&
            data.map((roundTData, index) => {
              return (
                <DialogContentText
                  key={`roundtripData${index}`}
                  style={{
                    display: 'flex',
                    gap: '14px',
                    alignItems: 'center',
                    marginTop: '5px',
                  }}
                >
                  {/* <div style={{ paddingBottom: '15px' }}>
                    {roundTData?.code}
                  </div> */}
                  <Autocomplete
                    noOptionsText={t('no_options')}
                    sx={{ flex: 1, width: '240px' }}
                    {...RoundtripProps}
                    autoComplete={false}
                    onChange={(e, value) => {
                      addInArrRoundrip(roundTData?.code, value, data, setData);
                    }}
                    value={data[index]}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${t('info.roundtrip')}`}
                        name="Roundtrip"
                        size="small"
                        variant="outlined"
                      />
                    )}
                  />
                  <If
                    condition={userContext === AgentCategories.Sales}
                    otherwise={
                      <Autocomplete
                        key={`autocomplete-driver-${keyArrayRef.current[index]}-${data[index]?.driver}`}
                        noOptionsText={t('no_options')}
                        sx={{ flex: 1, width: '240px' }}
                        {...UserProps}
                        autoComplete={false}
                        onChange={(e, value) => {
                          addInArrByRole(
                            roundTData?.code,
                            value,
                            data,
                            setData,
                            roleAgentId,
                            Driver,
                          );
                        }}
                        value={data[index]?.driver}
                        isOptionEqualToValue={(option, value) => {
                          return option?.['_id'] === value?.key;
                        }}
                        defaultValue={{
                          key: roundTData?.agents?.find(
                            (data) => data?._role?.key === Driver,
                          )?._id,
                          fullName: roundTData?.agents?.find(
                            (data) => data?._role?.key === Driver,
                          )?._user?.fullName,
                        }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            label={`${t('info.driver')}`}
                            name={Driver}
                            size="small"
                            variant="outlined"
                          />
                        )}
                        getOptionDisabled={(option_: any) => {
                          return !!option_?.onLeave;
                        }}
                      />
                    }
                  >
                    <Autocomplete
                      key={`autocomplete-sales-${keyArrayRef.current[index]}-${data[index]?.sales}`}
                      noOptionsText={t('no_options')}
                      sx={{ flex: 1, width: '240px' }}
                      {...UserProps}
                      autoComplete={false}
                      onChange={(e, value) => {
                        addInArrByRole(
                          roundTData?.code,
                          value,
                          data,
                          setData,
                          roleAgentId,
                          Sales,
                          organization?._id,
                        );
                      }}
                      value={data[index]?.sales}
                      isOptionEqualToValue={(option, value) => {
                        return option?.['_id'] === value?.key;
                      }}
                      defaultValue={{
                        key: roundTData?.agents?.find(
                          (data) => data?._role?.key === Sales,
                        )?._id,
                        fullName: roundTData?.agents?.find(
                          (data) => data?._role?.key === Sales,
                        )?._user?.fullName,
                      }}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={`${t('info.sales')}`}
                          name={Sales}
                          size="small"
                          variant="outlined"
                        />
                      )}
                      getOptionDisabled={(option_: any) => {
                        return !!option_?.onLeave;
                      }}
                    />
                  </If>

                  <Autocomplete
                    key={`autocomplete-truck-${keyArrayRef.current[index]}-${data[index]?._vehicle}`}
                    noOptionsText={t('no_options')}
                    sx={{ flex: 1, width: '240px' }}
                    {...VehiculeProps}
                    value={data[index]?.truck}
                    autoComplete={false}
                    onChange={(e, value) => {
                      addInArrVehicle(roundTData?.code, value, data, setData);
                    }}
                    isOptionEqualToValue={(option, value) => {
                      return option?._id === value?.key;
                    }}
                    defaultValue={{
                      key: roundTData?._vehicle?._id,
                      model: roundTData?._vehicle?.model,
                      numberPlate: roundTData?._vehicle?.numberPlate,
                      _id: roundTData?._vehicle?._id,
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={`${
                          userContext === AgentCategories.Sales
                            ? t('vehicle')
                            : t('info.truck')
                        }`}
                        name="vehicle"
                        size="small"
                        variant="outlined"
                      />
                    )}
                  />
                  {!assignStopsMode ? (
                    <div style={{ paddingBottom: '15px' }}>
                      <IconButton
                        aria-label="delete"
                        component="label"
                        onClick={() => {
                          deleteButton(
                            roundTData?.code,
                            setData,
                            data,
                            roundTripData,
                          );
                          removeKeyByIndex(index);
                        }}
                      >
                        <TrashSimple />
                      </IconButton>
                    </div>
                  ) : (
                    <div style={{ width: '37px' }} />
                  )}
                </DialogContentText>
              );
            })}
        </div>
        {!assignStopsMode && (
          <DialogContentText>
            <Button
              onClick={async () => {
                AddRoundTrip(
                  checkLastCode(true),
                  setData,
                  data,
                  dateAdd,
                  roleAgentId,
                );
                addKeyToArray();
              }}
            >
              <AddIcon />
              {t('roundtrips.edit_roundtrip.add_roundtrip')}
            </Button>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'right',
          width: '100%',
          margin: '11px 14px 11px 0',
          paddingRight: '33px',
        }}
      >
        <Button
          onClick={() => {
            // handleCloseActivate();
            setAnchorElActivate(false);
          }}
          color="inherit"
        >
          {t('common.buttons.cancel')}
        </Button>
        <Button
          onClick={() => {
            addFuction();
          }}
          variant="contained"
          autoFocus
          disabled={
            !data?.length ||
            //!!data.find(d => d?.internalCode === undefined) ||
            !!data?.find((d) => !d?.agents[0]?._user) ||
            !!data?.find((d) => !d?._vehicle) ||
            addingRoundtrips
          }
        >
          {!assignStopsMode ? t('common.add') : t('create_and_assign')}
        </Button>
      </DialogActions>
    </TwoViewModal>
  );
};
