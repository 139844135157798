import { AbilityBuilder, createMongoAbility } from '@casl/ability';
import { Application } from 'common/store/app/types';
import { selectRestrictedFields } from 'common/store/organization/selectors';
import { Permission } from 'hooks/Abilities/usePermission';
import { useEffect } from 'react';
import { useSelector } from 'react-redux';

export const useDefineAbilitiesFor = (
  authUser,
  currentApplication: Application,
) => {
  const { can, build, rules, cannot } = new AbilityBuilder(createMongoAbility);

  const restrictedFields = useSelector(selectRestrictedFields);

  const scopes = authUser?.currentAgent?.privileges?.scopes || [];
  const roleProperties =
    authUser?.currentAgent?.privileges?.roleproperties || [];

  const isAdmin = authUser?.isSuperAdmin; // || authUser?.currentAgent?._role?.key === 'admin';

  const canViewDashboardAll =
    (authUser?.agents?.filter((agent: any) =>
      agent?.privileges?.scopes.includes(
        `${currentApplication?.id}.dashboard.canview`,
      ),
    )?.length || 0) > 1;
  const canViewReportsAll =
    (authUser?.agents?.filter((agent: any) =>
      agent?.privileges?.scopes.includes(
        `${currentApplication?.id}.reports.canview`,
      ),
    )?.length || 0) > 1;

  if (canViewDashboardAll) {
    can('canview', 'dashboard-all');
  }
  if (canViewReportsAll) {
    can('canview', 'reports-all');
  }

  if (isAdmin) {
    can('manage', 'all');
  } else {
    scopes.forEach((scope) => {
      const [application, module, action] = scope.split('.');
      if (application === currentApplication?.id || application === 'idm') {
        can(action, module);
      }
    });

    roleProperties.forEach((property) => {
      const [application, active] = property.split('.');
      if (active === 'active') {
        can('access', application);
      }
    });

    restrictedFields?.forEach((rf) => {
      const { application, module, fields } = rf;
      if (application === currentApplication?.id || application === 'idm') {
        if (
          authUser?.currentAgent?.privileges?.scopes.includes(
            `${currentApplication?.id}.${module}.manageteam`,
          ) ||
          authUser?.currentAgent?.privileges?.scopes.includes(
            `idm.${module}.manageteam`,
          )
        ) {
          can(Permission.EDIT, module, fields);
        } else {
          cannot(Permission.EDIT, module, fields);
        }
      }
    });
  }

  const ability = build();

  useEffect(() => {
    if (isAdmin) {
      can('manage', 'all');
    } else {
      scopes.forEach((scope) => {
        const [application, module, action] = scope.split('.');
        if (application === currentApplication?.id || application === 'idm') {
          can(action, module);
        }
      });

      roleProperties.forEach((property) => {
        const [application, active] = property.split('.');
        if (active === 'active') {
          can('access', application);
        }
      });

      restrictedFields?.forEach((rf) => {
        const { application, module, fields } = rf;
        if (application === currentApplication?.id || application === 'idm') {
          if (
            authUser?.currentAgent?.privileges?.scopes.includes(
              `${currentApplication?.id}.${module}.manageteam`,
            ) ||
            authUser?.currentAgent?.privileges?.scopes.includes(
              `idm.${module}.manageteam`,
            )
          ) {
            can(Permission.EDIT, module, fields);
          } else {
            cannot(Permission.EDIT, module, fields);
          }
        }
      });
    }

    ability.update(rules);
  }, [authUser]);

  return ability;
};
