import { Grid } from 'app/components/Grid';
import { useGrid } from 'app/components/Grid/hooks';
import If from 'app/components/If';
import { LoadingIndicator } from 'app/components/LoadingIndicator';
import NoResults from 'app/components/NoData/NoResults';
import { EmptyCenter } from 'app/components/TableComponent/styles';
import { isEmpty } from 'lodash';
import { useEffect, useRef, useState } from 'react';
import { MAP } from 'app/components/TableComponent/constants';
import WarehouseMarker from 'assets/img/map/marker/WarehousePointMarker.svg';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { GRID } from 'app/components/TableComponent/constants';
import { useLazyCustomersPlacesQuery } from 'common/services/customerApi';
import { selectConfiguration } from 'common/store/organization/selectors';
import _ from 'lodash';
import { useSelector } from 'react-redux';
import { ZoomOptionMap } from '../../../../ZoomOptionMap';
import { Columns } from '../../../data';
import { Branch } from '../../Type';
import { Row } from '../../Row';
import { useApiCalls } from '../../../hooks/useApiCalls';
import { MarkerBranch } from 'app/pages/Customers/components/MarkerBranch';
import EmptyState from 'assets/img/customers/EmptyStateBranches.svg';
import { Button, Typography } from '@mui/material';
import { usePlaceContext } from '../../../hooks/usePlaceContext';
import { GridPagination } from 'app/components/Grid/components/GridPagination';
import { GoogleMapComponent } from 'app/components/GoogleMapComponent';
import { Marker } from '@vis.gl/react-google-maps';

export const BranchGrid = ({ type }) => {
  const { filters, setFilters, setupGrid, orderBy, setOrderBy, search } =
    useGrid();

  const mapBoundsRef = useRef('');

  const filterActivated =
    (filters?.deliveryTypes?.length ||
      filters?.vehicleCondition?.length ||
      filters?.status?.length) &&
    search === '';

  const { setDrawerOpen, canEdit } = usePlaceContext();

  const { t } = useTranslation();
  const { id } = useParams<Record<string, string>>();
  const { isLoading, branches } = useApiCalls(id || '');
  const [mapZoom, setMapZoom] = useState<any>(11);

  const [openClientId, setOpenClientId] = useState(null);
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const configuration = useSelector(selectConfiguration);

  const handleMarkerClick = (clientId) => {
    if (openClientId === clientId) {
      setOpenClientId(null);
    } else {
      setOpenClientId(clientId);
    }
  };

  const warehouses = configuration?.decks.filter(
    (deck) => deck.location?.geometry?.coordinates,
  );
  const defaultMapCenter = warehouses[0]?.location?.geometry?.coordinates
    ? {
        lat: configuration?.decks[0]?.location?.geometry?.coordinates[1],
        lng: configuration?.decks[0]?.location?.geometry?.coordinates[0],
      }
    : { lat: 48.8566, lng: 2.3522 };

  const [mapCenter, setMapCenter] = useState(defaultMapCenter);
  const containerStyle = {
    width: '100%',
    height: '100%',
  };

  useEffect(() => {
    if (type === MAP && !isFetchingMapData && map) {
      getBoundsFromMap(true);
    }
  }, [search]);

  useEffect(() => {
    if (map && type === MAP && !mapData && !isLoadingMapData) {
      setTimeout(() => {
        getBoundsFromMap(true);
      }, 10);
    }
  }, [map, type]);

  const throttledGetBoundsFromMap = _.throttle(() => {
    getBoundsFromMap(false);
  }, 1000);

  const getBoundsFromMap = (forceTrigger = false) => {
    if (map) {
      const bds = map.getBounds();

      if (bds) {
        const South_Lat = bds.getSouthWest().lat();
        const South_Lng = bds.getSouthWest().lng();
        const North_Lat = bds.getNorthEast().lat();
        const North_Lng = bds.getNorthEast().lng();

        var NWCorner_lat = new google.maps.LatLng(North_Lat, South_Lng).lat();
        var NWCorner_lng = new google.maps.LatLng(North_Lat, South_Lng).lng();

        var SECorner_lat = new google.maps.LatLng(South_Lat, North_Lng).lat();
        var SECorner_lng = new google.maps.LatLng(South_Lat, North_Lng).lng();

        const queryParams = [
          [
            [NWCorner_lng, NWCorner_lat],
            [North_Lng, North_Lat],
            [SECorner_lng, SECorner_lat],
            [South_Lng, South_Lat],
            [NWCorner_lng, NWCorner_lat],
          ],
        ];

        const newBoundsQueryParam = `bounds=${encodeURIComponent(
          JSON.stringify(queryParams),
        )}&text=${search}&limit=3500`;

        if (forceTrigger || mapBoundsRef.current !== newBoundsQueryParam) {
          triggerCustomer(newBoundsQueryParam);
        }

        mapBoundsRef.current = newBoundsQueryParam;

        if (map.getCenter()?.lat() && map.getCenter()?.lng()) {
          setMapCenter({
            // @ts-ignore
            lat: map.getCenter()?.lat(),
            // @ts-ignore
            lng: map.getCenter()?.lng(),
          });
        }
      }
    }
  };

  const [
    triggerCustomer,
    {
      data: mapData,
      isLoading: isLoadingMapData,
      isFetching: isFetchingMapData,
    },
  ] = useLazyCustomersPlacesQuery();

  useEffect(() => {
    if (!orderBy) {
      setOrderBy('name');
    }
    setupGrid({
      columns: Columns,
      rows: branches?.docs,
      totalRows: branches?.totalDocs || 0,
      withCheckbox: false,
    });
  }, [setupGrid, branches, orderBy, setOrderBy]);

  useEffect(() => {
    if (isEmpty(filters)) {
      setFilters({
        deliveryTypes: [],
        vehicleCondition: [],
        status: [],
      });
    }
  }, [filters, setFilters]);

  const handleSetZoom = (zoom) => {
    if (map && zoom !== mapZoom) {
      setMapZoom(zoom);

      if (zoom < mapZoom) {
        throttledGetBoundsFromMap();
      }
    }
  };

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <>
      {type === MAP && (
        <GoogleMapComponent
          containerStyle={containerStyle}
          disableDefaultUI={true}
          defaultCenter={mapCenter}
          zoom={mapZoom}
          setMap={setMap}
          onZoomChanged={handleSetZoom}
        >
          <>
            {warehouses?.map((warehouse) => {
              return (
                <Marker
                  key={warehouse._id}
                  icon={WarehouseMarker}
                  position={{
                    lat: warehouse.location?.geometry?.coordinates[1],
                    lng: warehouse.location?.geometry?.coordinates[0],
                  }}
                />
              );
            })}
            {branches?.docs?.map((client) => {
              return (
                <MarkerBranch
                  key={client._id}
                  coordinates={client?.location?.geometry?.coordinates}
                  code={client?.code}
                  name={client?._customer?.legalName}
                  isSelected={client?.code === openClientId}
                  onToggleOpen={() => handleMarkerClick(client.code)}
                  names={true}
                  branch={client}
                />
              );
            })}
            <ZoomOptionMap mapZoom={mapZoom} setMapZoom={handleSetZoom} />
          </>
        </GoogleMapComponent>
      )}

      {type === GRID && (
        <>
          <Grid>
            {branches?.docs?.map((branch: Branch) => {
              return <Row key={branch._id} branch={branch} />;
            })}
          </Grid>
          <div
            style={{
              position: 'absolute',
              bottom: 0,
              right: 0,
              zIndex: 1000,
              width: '100%',
              backgroundColor: 'white',
            }}
          >
            <GridPagination />
          </div>
        </>
      )}

      <If
        condition={!branches?.docs?.length && type === GRID && filterActivated}
      >
        <EmptyCenter>
          <NoResults />
        </EmptyCenter>
      </If>

      <If
        condition={!branches?.docs?.length && type === GRID && !filterActivated}
      >
        <EmptyCenter>
          <img src={EmptyState} alt="Empty State" />
          <Typography
            fontWeight={400}
            fontSize="13px"
            sx={{
              mt: '16px',
              mb: '16px',
            }}
          >
            {t('emptyState.branches')}
          </Typography>
          <If condition={!!canEdit}>
            <Button variant="contained" onClick={() => setDrawerOpen(true)}>
              {t('customer.addBranch.title')}
            </Button>
          </If>
        </EmptyCenter>
      </If>
    </>
  );
};
