import React from 'react';
import styled from 'styled-components';
import { FormSection } from 'app/components/Form/styles';
import { useTranslation } from 'react-i18next';
import {
  RadioGroup,
  FormControlLabel,
  FormGroup,
  Checkbox,
  Button,
} from '@mui/material';
import {
  ClientInformationTitle,
  ClientInformationTitleContainer,
} from '../../../styles';
import { FieldComponent } from 'app/components/FieldComponent';
import { useForm, Controller } from 'react-hook-form';
import PhoneInput from 'react-phone-input-2';
import { ChipWithRadio } from '../../../ChipWithRadio';
import If from 'app/components/If';
import { Actions, CancelButton } from 'app/components/Dialog/styles/index';

interface Props {
  onSubmit: (data: any) => void;
  data?: any;
  renderFooter: boolean;
  closeModal?: () => void;
}

export const AddPhoneForm: React.FC<Props> = ({ onSubmit, data, renderFooter, closeModal }) => {
  const { t } = useTranslation();
  const { control, handleSubmit, watch} = useForm({
    defaultValues: {
      fullName: data?.name || '',
      jobRole: data?.role || '',
      phone: data?.phone || '',
      main: data?.main ? data?.main : false,
      jobType: data?.jobType,
    },
  });

  const jobType = watch('jobType');
  const phone = watch('phone');
  const fullName = watch('fullName');
  
  const StyledWrapper = styled.div`
    .special-label {
      font-size: 11px !important;
      color: #b4b3b3;
      position: absolute;
      left: 10px !important;
    }
  `;

  return (
    <>
    <form id="add-new-form" onSubmit={handleSubmit(onSubmit)}>
      <FormSection>
        <ClientInformationTitleContainer>
          <ClientInformationTitle>
            {t('customer.field.jobType')}
          </ClientInformationTitle>
        </ClientInformationTitleContainer>
        <Controller
          name="jobType"
          control={control}
          render={({ field }) => (
            <RadioGroup
              row
              aria-labelledby="job-type-radio-buttons-group-label"
              name="job-type-radio-buttons-group"
              value={field.value}
              onChange={(e) => field.onChange(e.target.value)}
            >
              <FormControlLabel
                value="logistics"
                control={<ChipWithRadio
                  value="logistics"
                  label={t('info.logistics')}
                  selectedValue={field.value} />}
                label="" />
              <FormControlLabel
                value="sales"
                control={<ChipWithRadio
                  value="sales"
                  label={t('report.filters.sales')}
                  selectedValue={field.value} />}
                label="" />
            </RadioGroup>
          )} />

        <Controller
          name="fullName"
          control={control}
          rules={{ required: true }}
          render={({ field }) => (
            <FieldComponent
              {...field}
              control={control}
              style={{
                width: 'calc(100% - 10px)',
                marginTop: '25px',
                marginBottom: '8px',
              }}
              size="small"
              label={t('customer.field.fullName')}
              placeholder={t('Enter Full Name')}
              disabled={false}
              variant="outlined"
              required />
          )} />

        <StyledWrapper>
          <Controller
            name="phone"
            control={control}
            rules={{ required: true }}
            render={({ field }) => (
              <PhoneInput
                {...field}
                country="fr"
                specialLabel={t('Add_Client_Mobile_Phone')}
                inputStyle={{
                  height: '37.13px',
                  width: 'calc(100% - 10px)',
                }}
                inputProps={{
                  name: 'phone',
                }} />
            )} />
        </StyledWrapper>

        <Controller
          name="jobRole"
          control={control}
          render={({ field }) => (
            <FieldComponent
              {...field}
              control={control}
              style={{ width: 'calc(100% - 10px)', marginTop: '25px' }}
              size="small"
              label={t('customer.field.jobRole')}
              placeholder={t('Enter Job Role')}
              disabled={false}
              variant="outlined" />
          )} />
        <FormGroup>
          <Controller
            name="main"
            control={control}
            render={({ field }) => (
              <FormControlLabel
                control={<Checkbox
                  {...field}
                  defaultChecked={control?._defaultValues?.main} />}
                label={t('customer.field.markAsMain')} />
            )} />
        </FormGroup>
      </FormSection>
    </form>
    <If condition={renderFooter}>
        <Actions>
          <CancelButton onClick={() => {
            if (closeModal) {
              closeModal();
            }
          }}>
            {t('Add_Client_Cancel')}
          </CancelButton>
          <Button variant="contained" onClick={handleSubmit(onSubmit)} disabled={!(phone !== '' && fullName !== '' && jobType)}>{t('common.buttons.add')}</Button>
        </Actions>
      </If>
    </>
  );
};
