import React, { useState, useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { selectTheme } from 'styles/theme/slice/selectors';
import { MarkerStep, MarkerVehicle } from 'app/components/MapMarkers';
import { getMostRecentItem } from 'app/helpers/helpers';
import { centroidLabel } from 'utils/geo';
import styled from 'styled-components';
import { selectDecks } from 'common/store/organization/selectors';
import { StatisticCard } from '../../../StatisticCard';
import { useTranslation } from 'react-i18next';
import If from 'app/components/If';
import { StopStatuses } from '../../../StatisticCard/components/StopStatuses';
import dayjs from 'dayjs';
import { RoundtripDetailsDrawer } from 'app/pages/RoundTrips/components/Map/components/RoundtripDetailsDrawer';
import { StopMarker } from './components/StopMarker';
import { themes } from 'styles/theme/themes';
import { GoogleMapComponent } from 'app/components/GoogleMapComponent';

interface Props {
  roundtrip: any;
  isFetching?: boolean;
  onSelectOrder?: Function;
}

export const RoundtripMap: React.FC<Props> = ({
  roundtrip,
  isFetching,
  onSelectOrder,
}) => {
  const { t, i18n } = useTranslation();
  const decks = useSelector(selectDecks);
  const theme = useSelector(selectTheme);
  const weekDay = dayjs()
    .locale(i18n.language || 'en')
    .format('dddd');

  const [map, setMap] = useState<google.maps.Map | null>(null);

  const markers = useMemo(() => {
    const m: any = {
      vehicles: [],
      routes: [],
    };

    const polygonPath: { type?: string; lat: number; lng: number }[] = [];
    const canceledPolygonPath: { lat: number; lng: number }[] = [];
    const lastPosition: any =
      roundtrip.status === 'parked'
        ? roundtrip._vehicle?.lastPosition
        : getMostRecentItem(
            [roundtrip._vehicle?.lastPosition, roundtrip.lastAgentPosition],
            'date',
          );

    if (lastPosition?.geometry) {
      m.vehicles.push({
        roundtripId: roundtrip._id,
        numberPlate: roundtrip._vehicle.numberPlate,
        lastPosition,
      });
    }

    // Start place
    if (roundtrip._startPlace) {
      polygonPath.push({
        type: 'start',
        lat: roundtrip._startPlace.location.geometry.coordinates[1],
        lng: roundtrip._startPlace.location.geometry.coordinates[0],
      });
    }

    roundtrip.stops.forEach((stop, index) => {
      const isCanceled =
        stop?._order?.status === 'canceled' ||
        stop?._order?.status === 'unloaded';
      if (stop._place?.location?.geometry?.coordinates && !isCanceled) {
        polygonPath.push({
          type: stop.status !== 'canceled' ? 'canceled' : '',
          lat: stop._place.location.geometry.coordinates[1],
          lng: stop._place.location.geometry.coordinates[0],
        });
      }

      if (stop._place?.location?.geometry?.coordinates && isCanceled) {
        canceledPolygonPath.push(
          // stop before the canceled
          {
            lat:
              roundtrip.stops[index - 1]?._place.location.geometry
                .coordinates[1] ||
              roundtrip._startPlace.location.geometry.coordinates[1],
            lng:
              roundtrip.stops[index - 1]?._place.location.geometry
                .coordinates[0] ||
              roundtrip._startPlace.location.geometry.coordinates[0],
          },
          // canceled stop
          {
            lat: stop._place.location.geometry.coordinates[1],
            lng: stop._place.location.geometry.coordinates[0],
          },
          // stop after the canceled
          {
            lat:
              roundtrip.stops[index + 1]?._place.location.geometry
                .coordinates[1] ||
              roundtrip._endPlace.location.geometry.coordinates[1],
            lng:
              roundtrip.stops[index + 1]?._place.location.geometry
                .coordinates[0] ||
              roundtrip._endPlace.location.geometry.coordinates[0],
          },
        );
      }
    });

    if (roundtrip._endPlace) {
      polygonPath.push({
        type: 'end',
        lat: roundtrip._endPlace.location.geometry.coordinates[1],
        lng: roundtrip._endPlace.location.geometry.coordinates[0],
      });
    }

    m.routes.push({
      options: {
        fillColor: theme.primary,
        fillOpacity: 0.3,
        strokeColor: theme.primary,
        strokeOpacity: 1,
        strokeWeight: 3,
        clickable: false,
        draggable: false,
        editable: false,
        geodesic: false,
        zIndex: 3,
      },
      center: centroidLabel(polygonPath, false, false),
      paths: polygonPath,
      canceledPaths: canceledPolygonPath,
      canceledPathsOptions: {
        fillColor: 'transparent',
        fillOpacity: 0.2,
        strokeColor: themes?.default?.redA400,
        strokeOpacity: 0.4,
        strokeWeight: 3,
        zIndex: 2,
      },
      code: roundtrip.code,
      roundtripId: roundtrip._id,
    });

    return m;
  }, [roundtrip]);

  const displayRoutes = useMemo(() => {
    return markers.routes.filter((p) => roundtrip?._id === p.roundtripId);
  }, [roundtrip?._id, markers?.routes]);

  const center = useMemo(() => {
    if (displayRoutes?.length) {
      return {
        lat: displayRoutes?.[0]?.center?.[1],
        lng: displayRoutes?.[0]?.center?.[0],
      };
    }
    return {
      lat: 48,
      lng: 2,
    };
  }, [displayRoutes]);

  const roundtripDeck = useMemo(() => {
    return decks?.find((deck) => deck?._id === roundtrip?._startPlace?._id);
  }, [roundtrip, decks]);

  const stopsWithOrder = useMemo(() => {
    const stops = [...roundtrip.stops];
    return stops?.filter((stop) => stop?._order);
  }, [roundtrip.stops]);
  const stopsWithoutOrder = useMemo(() => {
    const stops = [...roundtrip.stops];
    return stops?.filter((stop) => !stop?._order);
  }, [roundtrip.stops]);

  function handleRoundtripFocus(roundtrip) {
    const allOrdersCoordinates: any[] = [];
    roundtrip?.stops?.map((order) => {
      if (order?.type === 'fuel' || order?.type === 'maintenance') {
        return allOrdersCoordinates.push(
          order?._place?.location?.geometry?.coordinates,
        );
      }
      allOrdersCoordinates.push(
        order?._order?._deck?.location?.geometry?.coordinates,
      );
    });
    allOrdersCoordinates.push(
      roundtrip?._startPlace?.location?.geometry?.coordinates,
    );
    allOrdersCoordinates.push(
      roundtrip?._endPlace?.location?.geometry?.coordinates,
    );

    if (allOrdersCoordinates.length === 0) return;

    const bounds = new window.google.maps.LatLngBounds();
    allOrdersCoordinates.forEach((location) => {
      bounds.extend({
        lat: parseFloat(location[1]),
        lng: parseFloat(location[0]),
      });
    });

    if (map) {
      map.setOptions({ maxZoom: 11 });
      map.fitBounds(bounds);
      map.setOptions({ maxZoom: null });
    }
  }

  useEffect(() => {
    if (!map) return;
    handleRoundtripFocus(roundtrip);
  }, [map, roundtrip.stops]);

  const cardsSx = { minWidth: '250px' };

  return (
    <MapWrapper id="mapContainer">
      <DetailsWrapper>
        <If condition={roundtrip && !isFetching}>
          <RoundtripDetailsDrawer
            selectedRoundtripDetails={roundtrip}
            disableEdit={true}
            hideHeader={true}
          />
        </If>
      </DetailsWrapper>

      <GoogleMapComponent
        defaultCenter={center}
        defaultZoom={10}
        disableDefaultUI={true}
        polygons={displayRoutes}
        setMap={setMap}
      >
        <>
          <If condition={!isFetching}>
            {markers?.vehicles?.map((v) => (
              <MarkerVehicle key={v._id} vehicle={v} />
            ))}

            {roundtripDeck?.location && (
              <MarkerStep
                key={roundtripDeck?._id}
                type="deck"
                place={roundtripDeck?.location?.geometry}
              />
            )}

            {stopsWithOrder?.map((s, stopIndex) => {
              const position = {
                lat: s?._place?.location?.geometry?.coordinates[1],
                lng: s?._place?.location?.geometry?.coordinates[0],
              };
              return (
                <StopMarker
                  key={s._id}
                  position={position}
                  order={s?._order}
                  index={stopIndex + 1}
                  onDisplayOrder={onSelectOrder}
                  map={map}
                />
              );
            })}
            {stopsWithoutOrder?.map((s) => {
              return (
                <MarkerStep
                  type="stop"
                  stopType={s.type}
                  key={s._id}
                  place={s._place}
                  data={s}
                />
              );
            })}
          </If>
          <BottomSection>
            <StatisticCard
              loading={isFetching}
              sxProps={cardsSx}
              title={t('dashboard.number_stops')}
              value={`${roundtrip?.cache?.stats?.stops || 0}`}
              variation={roundtrip?.variations?.stops}
              BottomSection={<StopStatuses stats={roundtrip?.cache?.stats} />}
              variationTooltip={t('variaiton_comparing_to_day', {
                day: weekDay,
              })}
            />
            <StatisticCard
              loading={isFetching}
              sxProps={cardsSx}
              title={t('dashboard.total_weight')}
              value={`${Math.round(
                roundtrip?.cache?.stats?.totalWeight || 0,
              )?.toLocaleString()}`}
              variation={roundtrip?.variations?.weight}
              variationTooltip={t('variaiton_comparing_to_day', {
                day: weekDay,
              })}
            />
            <StatisticCard
              loading={isFetching}
              sxProps={cardsSx}
              title={t('dashboard.total_distance')}
              value={`${Math.round(
                (roundtrip.cache?.directions?.distance || 0) / 1000,
              )?.toLocaleString()}`}
              variation={roundtrip?.variations?.distance}
              variationTooltip={t('variaiton_comparing_to_day', {
                day: weekDay,
              })}
            />
            <StatisticCard
              loading={isFetching}
              sxProps={cardsSx}
              title={t('ca_kg')}
              value={`${roundtrip?.amountPerWeight}`}
              variation={roundtrip?.variations?.amountPerWeight}
              variationTooltip={t('variaiton_comparing_to_day', {
                day: weekDay,
              })}
            />
            <StatisticCard
              loading={isFetching}
              sxProps={cardsSx}
              title={t('c02_consumption')}
              value={`${roundtrip?.co2Consumption?.toLocaleString()}`}
              variation={roundtrip?.variations?.co2Consumption}
              variationTooltip={t('variaiton_comparing_to_day', {
                day: weekDay,
              })}
            />
          </BottomSection>
        </>
      </GoogleMapComponent>
    </MapWrapper>
  );
};

export default React.memo(RoundtripMap);

const DetailsWrapper = styled.div`
  width: 250px;
  background: white;
  border-right: 1px solid ${themes?.default?.lightGrey3};
  box-shadow: 0 6px 10px 0 ${themes?.default?.lightGrey3};
  z-index: 1;
`;

const BottomSection = styled.div`
  position: absolute;
  bottom: 15px;
  display: flex;
  width: 100%;
  padding: 20px 20px 5px 20px;
  gap: 16px;
  overflow: scroll;
`;

const MapWrapper = styled.div((props) => ({
  height: `calc(calc(100% - ${props.theme.topBarHeight}) - 1rem)`,
  '& .marker-label': {
    fontSize: '0.5625rem !important',
    fontWeight: 700,
    width: '23px',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  flexGrow: 1,
  display: 'flex',
}));
