import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { Menu, Tooltip } from '@mui/material';
import { useSelector } from 'react-redux';
import { Draggable } from 'react-beautiful-dnd';
import React, {
  useLayoutEffect,
  useRef,
  useState,
  useEffect,
  useCallback,
  useMemo,
} from 'react';
import { StatusOrder } from '../../data/RoundTripData/components/StatusOrder';
import { vehicleCondition } from '../functions/function';
import { ActionsStop } from '../../data/RoundTripData/components/components/ActionsStop';
import { useToaster } from 'hooks/useToaster';
import If from 'app/components/If';
import StopIcon from '../../../../components/StopIcon';
import MapPopover from 'app/components/MapPopover';
import { handleCloseStopMap } from '../../functions';
import { useDeleteOrderClusterMutation } from 'common/services/roundtripApi';
import { themes } from 'styles/theme/themes';
import OrderPriority from 'app/components/OrderPriority';
import { removeItemsFromOrder } from '../RightBar/function';
import { useModal } from 'app/components/Dialog/hooks';
import { getOrderStatusesToShow } from 'app/helpers/helpers';
import { selectConfiguration } from 'common/store/organization/selectors';
import { OrderStatusDot } from 'app/components/OrderStatusDot';
import {
  CrosshairSimple,
  ArrowSquareRight,
  ArrowsCounterClockwise,
  HandPointing,
  PushPin,
} from '@phosphor-icons/react';
import { ChangePositionPopover } from 'app/pages/AddRoundTrips/data/RoundTripData/components/ChangePositionPopover';
import { SwitchStopPopover } from 'app/pages/AddRoundTrips/data/RoundTripData/components/SwitchStopPopover';
import { OrderAssignPopover } from 'app/pages/AddRoundTrips/data/RoundTripData/components/OrderAssignPopover';
import { deleteFunctionStop } from 'app/components/RoundTrips/function/ApiFunctions';
import { useDeleteStopMutation } from 'common/services/roundtripApi';
import { useLazyGetRoundTripDetailsQuery } from 'common/services/roundtripApi';
import { bindShortcut } from 'app/pages/RoundTrips/components/KeyboardShortcuts/functions';
import EllipsisText from 'app/components/EllipsisText';
import { Can } from 'hooks/Abilities/context';
import { formatChipDate } from 'app/pages/AddRoundTrips/data/RoundTripData/components/StatusOrder';

interface CardProps {
  ref?: any;
  handleChange2?: any;
  data?: any;
  stop?: any;
  isAssigned?: boolean;
  checked?: any;
  index?: any;
  onDisplayOrder?: any;
  dateValue?: any;
  accordionStatus?: any;
  setAccordionStatus?: any;
  roundTripCopy?: any;
  clusterId?: any;
  requestOrderData?: any;
  orders?: any;
  setOrders?: any;
  orderInitCount?: any;
  setOrderInitCount?: any;
  disableDragAndDrop?: any;
  mapVersion?: any;
  pinnedLayers?: any;
  setAssignedOrders?: any;
  disableEdit?: boolean;
  map?: any;
}

export const ItemOrder: React.FC<CardProps> = ({
  handleChange2,
  data,
  stop,
  isAssigned,
  checked,
  index,
  onDisplayOrder,
  accordionStatus,
  setAccordionStatus,
  roundTripCopy,
  clusterId,
  requestOrderData,
  orders,
  setOrders,
  orderInitCount,
  setOrderInitCount,
  disableDragAndDrop,
  mapVersion,
  pinnedLayers,
  setAssignedOrders,
  disableEdit = false,
  map,
}) => {
  const { t } = useTranslation();
  const [anchorEl_, setAnchorEl_] = useState<null | HTMLElement>(null);
  const [anchorEl_position, setAnchorEl_position] =
    useState<null | HTMLElement>(null);
  const [anchorEl_switchStop, setAnchorEl_switchStop] =
    useState<null | HTMLElement>(null);
  const [openDelete, setOpenDelete] = useState<boolean>(false);
  const [selectedStop, setSelectedStop] = useState<any>({});
  const [addRoundTripOpen, setAddRoundTripOpen] = useState<any>(null);
  const [hover, setHover] = useState<boolean>(false);

  const configuration: any = useSelector(selectConfiguration);
  const toast = useToaster();

  const handleLocationClick = (e) => {
    e?.stopPropagation();
    setMapOpen(true);
  };
  const myElementRef = useRef<HTMLDivElement>(null);
  const [scrollWdth, setScrollWdth] = useState(0);

  const [mapOpen, setMapOpen] = useState(false);

  useLayoutEffect(() => {
    if (myElementRef?.current) {
      const width = myElementRef.current.offsetWidth;
      setScrollWdth(width + 200 - 340);
    } else {
    }
  }, []);

  const formattedTime = useMemo(() => {
    if (data?.cache?._deck) {
      return `${data?.cache?._deck?.deliveryHours[0]?.begin} - ${data?.cache?._deck?.deliveryHours[0]?.end}`;
    } else if (data?._deck) {
      return `${data?._deck?.deliveryHours[0]?.begin} - ${data?._deck?.deliveryHours[0]?.end}`;
    } else {
      return null;
    }
  }, [data]);

  const [deleteStopRight] = useDeleteOrderClusterMutation();

  const updateOrders = useCallback(() => {
    const updatedOrders = removeItemsFromOrder(orders, [selectedStop]);
    setOrderInitCount(Math.max(orderInitCount - 1 || 0, 0));
    setOrders(updatedOrders);
  }, [orders, selectedStop, orderInitCount]);

  const [deleteStop] = useDeleteStopMutation();

  const handleClickAddRoundTrip = useCallback(
    (event: React.MouseEvent<any>) => {
      event?.stopPropagation();
      setAnchorEl_(event.currentTarget);
      setAddRoundTripOpen(event.currentTarget);
    },
    [anchorEl_, addRoundTripOpen],
  );

  const handleAddRoundTripMenuClose = useCallback(
    (e) => {
      e?.stopPropagation();
      setHover(false);
      setAnchorEl_(null);
    },
    [hover, anchorEl_],
  );

  const handleDisplayOrder = (order) => {
    if (onDisplayOrder) {
      onDisplayOrder(order);
    }
  };

  const orderStatusesList = getOrderStatusesToShow(
    configuration?.orderStatusLogistic || [],
  );

  const { closeModal } = useModal();

  const [triggerRoundtripDetails] = useLazyGetRoundTripDetailsQuery();

  const handleDelete = useCallback(
    async (stop, selectedRoundtrip) => {
      if (!isAssigned) {
        closeModal();
        setOpenDelete(false);
        updateOrders();
        await deleteStopRight({
          id: clusterId,
          orderIds: [stop._id],
        });
        toast(5000, 'success', 'alerts.orders.delete_success');
        setOrders((prevState) =>
          prevState.filter((order) => order?._id !== stop._id),
        );
        requestOrderData();
      } else {
        closeModal();
        setOpenDelete(false);
        updateOrders();
        requestOrderData();
        await deleteFunctionStop(deleteStop, selectedRoundtrip, stop);
        await triggerRoundtripDetails({ roundtripId: selectedRoundtrip.id });
        toast(5000, 'success', 'stops.snackbar.stopRemoved');
      }
    },
    [isAssigned, openDelete, clusterId, stop],
  );

  const pinned = useMemo(() => {
    if (pinnedLayers?.includes(data?._id)) return true;
    else return false;
  }, [pinnedLayers]);

  const [rtId, setRtId] = useState('');
  const [rtStops, setRtStops]: any = useState([]);
  const [currentRt, setCurrentRt]: any = useState();

  const handleSetCurrentroundtripInfo = useMemo(() => {
    return () => {
      roundTripCopy?.map((rt) => {
        rt?.stops?.map((st) => {
          if (st?._order?._id === data?._id) {
            setRtId(rt._id);
            setRtStops(rt.stops);
            setCurrentRt(rt);
          }
        });
      });
    };
  }, [roundTripCopy]);

  useEffect(() => {
    handleSetCurrentroundtripInfo();
  }, [handleSetCurrentroundtripInfo]);

  const handlePinLayer = (e) => {
    const isMenuClick = e.currentTarget.contains(e.target);
    if (isMenuClick) {
      handleChange2(data?._id, e);
    }
  };

  const changePositionBtnRef = useRef<any>(null);
  const assingToRoundtripBtnRef = useRef<any>(null);
  const switchStopBtnRef = useRef<any>(null);

  function closePopovers() {
    setAnchorEl_position(null);
    setAnchorEl_(null);
    setAnchorEl_switchStop(null);
  }

  function toggleChangePosition() {
    if (!!anchorEl_position) {
      setAnchorEl_position(null);
    } else {
      closePopovers();
      changePositionBtnRef?.current?.click();
    }
  }

  function toggleAssignToRoundtrip() {
    if (!!anchorEl_) {
      setAnchorEl_(null);
    } else {
      closePopovers();
      assingToRoundtripBtnRef?.current?.click();
    }
  }

  function toggleSwitchStop() {
    if (!!anchorEl_switchStop) {
      setAnchorEl_switchStop(null);
    } else {
      closePopovers();
      switchStopBtnRef?.current?.click();
    }
  }

  function removeStopFromOrders(orderId) {
    setOrders((prev) => prev.filter((order) => order?._id !== orderId));
  }

  const shortcuts = [
    {
      keybinds: ['ctrl+r', 'command+r'],
      callback: () => toggleAssignToRoundtrip(),
      listener: 'keyup',
    },
    {
      keybinds: ['ctrl+s', 'command+s'],
      callback: () => toggleSwitchStop(),
      listener: 'keyup',
    },
    {
      keybinds: ['ctrl+p', 'command+p'],
      callback: () => toggleChangePosition(),
      listener: 'keyup',
    },
    {
      keybinds: ['esc', 'escape'],
      callback: () => closePopovers(),
      stopPropagation: true,
    },
  ];

  const handleBindShortcuts = useMemo(() => {
    return () => {
      shortcuts.map((shortcut) => {
        bindShortcut(
          shortcut.keybinds,
          shortcut.callback,
          shortcut.stopPropagation,
          shortcut.listener,
        );
      });
    };
  }, []);

  useEffect(() => {
    handleBindShortcuts();
  }, [handleBindShortcuts]);

  const deliveredAt = useMemo(() => {
    if (!data?.updatedStatusAt) return;
    if (
      data?.status?.toLowerCase() === 'delivered' ||
      data?.status?.toLowerCase() === 'canceled'
    ) {
      return formatChipDate(data?.updatedStatusAt);
    }
  }, [data]);

  const parentRef: any = useRef(null);
  const zoomCooldown = useRef(false);

  useEffect(() => {
    if (!parentRef.current) return;

    const handlePinchZoom = (e) => {
      if (e?.ctrlKey && map) {
        e.preventDefault();
        if (zoomCooldown.current) return;

        const currentZoom = map.getZoom() || 1;
        if (e?.deltaY < 0) {
          if (currentZoom >= 22) return;
          map?.setZoom(currentZoom + 1);
        } else if (e?.deltaY > 0) {
          if (currentZoom <= 2) return;
          map?.setZoom(currentZoom - 1);
        }

        zoomCooldown.current = true;
        setTimeout(() => {
          zoomCooldown.current = false;
        }, 500);
      }
    };

    const currentInfoWindow = parentRef.current;

    currentInfoWindow.addEventListener('wheel', handlePinchZoom, {
      passive: false,
    });

    return () => {
      currentInfoWindow.removeEventListener('wheel', handlePinchZoom);
    };
  }, [parentRef, map]);

  return (
    <div ref={parentRef}>
      <Draggable
        draggableId={data?.id}
        index={index}
        data={data}
        checked={checked}
        key={data?.id}
        isDragDisabled={
          disableDragAndDrop === true ||
          (checked?.length === 0
            ? false
            : !checked?.some((check) => check.id === data?.id))
        }
      >
        {(provided, snapshot) => (
          <Data
            className="itemOrder"
            onClick={(e) => {
              return e.preventDefault();
            }}
            onDoubleClick={handlePinLayer}
            onMouseEnter={() => {
              setHover(true);
            }}
            onMouseLeave={() => {
              setHover(false);
            }}
            scrollWdth={scrollWdth}
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            style={{
              ...provided.draggableProps.style,
              visibility: snapshot.isDropAnimating ? 'hidden' : 'visible',
              opacity: snapshot.isDragging ? '0.4' : '1',
            }}
            checked={checked?.some((check) => check.id === data?.id)}
            mapVersion={mapVersion}
          >
            <CardBody>
              <CardInfoContainer>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                  }}
                >
                  {formattedTime ? (
                    <label>{formattedTime}</label>
                  ) : (
                    <p
                      style={{
                        margin: 0,
                        fontSize: '0.5rem',
                        color: themes?.default?.buttonGreyText,
                      }}
                    >
                      {t('time_not_defined')}
                    </p>
                  )}
                  {mapVersion && (
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        gap: '8px',
                      }}
                    >
                      {orderStatusesList?.map((item) => {
                        return (
                          item?.status === data?.status && (
                            <span style={{ alignSelf: 'flex-start' }}>
                              <StatusOrder
                                status={data?.status}
                                deliveredAt={deliveredAt}
                                minimal={true}
                                deliveryDistance={data?.deliveryInfo?.distance}
                              />
                            </span>
                          )
                        );
                      })}
                      <PushPin
                        onClick={handlePinLayer}
                        size={12}
                        weight="fill"
                        color={
                          pinned
                            ? themes?.default?.primary
                            : themes?.default?.lightGrey2
                        }
                        style={{
                          cursor: 'pointer',
                        }}
                      />
                    </div>
                  )}
                </div>
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      gap: '4px',
                    }}
                  >
                    {mapVersion ? (
                      <>
                        <p style={{ margin: 0 }}>
                          <span className="clientCode">
                            {data?._customer?.code}
                          </span>
                          {` - `}
                        </p>
                        <EllipsisText
                          text={data?._customer?.publicName}
                          width={200}
                        />
                      </>
                    ) : (
                      <p>
                        <span className="clientCode">
                          {data?._customer?.code}
                        </span>
                        {` - ${data?._customer?.publicName}`}
                      </p>
                    )}
                  </div>
                </div>
                <p className="orderCode">
                  {t('confirmDelivery.order')}{' '}
                  <span
                    onClick={(e) => {
                      e?.stopPropagation();
                      handleDisplayOrder(
                        data?._order
                          ? {
                              ...data?._order,
                              priority: data?.priority || stop?.priority,
                            }
                          : {
                              ...data,
                              id: data?._id,
                              priority: data?.priority || stop?.priority,
                            },
                      );
                    }}
                    className="code"
                  >{`#${data?.code}`}</span>{' '}
                </p>
              </CardInfoContainer>
              <CardTagsContainer
                scrollWdth={scrollWdth}
                mapVersion={mapVersion}
              >
                <div className="container">
                  <div ref={myElementRef} className="content">
                    {(data?._deck?.vehicleCondition ||
                      data?.cache?._deck?.vehicleCondition) &&
                      !!vehicleCondition(data)?.length && (
                        <div className="gray_value ">
                          {vehicleCondition(data)}
                        </div>
                      )}

                    <div className="gray_value">
                      {data?.cache?.stats?.totalWeight?.toFixed(2) || 0} KG
                    </div>
                    <div className="gray_value">
                      {0} {`m${'\u00B3'}`}
                    </div>
                    <div className="gray_value">
                      {data?.cache?.stats?.countLines || 0} ITEMS
                    </div>
                    <div
                      className="gray_value"
                      style={{ textTransform: 'uppercase' }}
                    >
                      {stop?._order?.supportUnitsRequestedCapacity || 0}{' '}
                      {t('home.support_units')}
                    </div>
                  </div>
                </div>
              </CardTagsContainer>
            </CardBody>
            <CardFooter>
              <div className="cardStatus">
                {orderStatusesList?.map((item) => {
                  return (
                    item?.status === data?.status && (
                      <OrderStatusDot
                        tooltipTitle={t(`orders.status.${data?.status}`)}
                        circleColor={item?.color}
                        size={8}
                      />
                    )
                  );
                })}
                <div className="check">
                  <OrderPriority priority={data?.priority || stop?.priority} />
                </div>
                <StopIcon
                  type={data?.type}
                  tooltipMessage={t(
                    `common.${data?.type}` || 'roundtrips.tooltip.collection',
                  )}
                />
              </div>
              {mapVersion || hover ? (
                <div className="cardAction">
                  {!mapVersion && (
                    <Tooltip title={t('roundtrip.locate_on_map')} arrow>
                      <Action onClick={handleLocationClick}>
                        <CrosshairSimple
                          size={13}
                          color={
                            mapOpen
                              ? themes?.default?.primary
                              : themes?.default?.iconColor
                          }
                        />
                      </Action>
                    </Tooltip>
                  )}
                  {!!roundTripCopy?.length && (
                    <Can I="canedit" a="orders">
                      <Tooltip title={t('orders.add_to_roundtrip')} arrow>
                        <Action
                          ref={assingToRoundtripBtnRef}
                          onClick={(e) => {
                            handleClickAddRoundTrip(e);
                            setSelectedStop(data);
                          }}
                        >
                          <ArrowSquareRight
                            size={13}
                            color={
                              !anchorEl_
                                ? themes?.default?.iconColor
                                : themes?.default?.primary
                            }
                          />
                        </Action>
                      </Tooltip>
                    </Can>
                  )}
                  {mapVersion && rtStops?.length > 1 && (
                    <>
                      <Tooltip title={t('roundtrip.switch_stop')} arrow>
                        <Action
                          ref={switchStopBtnRef}
                          onClick={(e) => {
                            e?.stopPropagation();
                            setAnchorEl_switchStop(e.currentTarget);
                          }}
                        >
                          <ArrowsCounterClockwise
                            size={13}
                            color={
                              !anchorEl_switchStop
                                ? themes?.default?.iconColor
                                : themes?.default?.primary
                            }
                          />
                        </Action>
                      </Tooltip>
                      <Menu
                        open={!!anchorEl_switchStop}
                        anchorEl={anchorEl_switchStop}
                        onClose={(e: any) => {
                          e?.stopPropagation();
                          setAnchorEl_switchStop(null);
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <SwitchStopPopover
                          setAnchorEl_switchStop={setAnchorEl_switchStop}
                          currentRoundtrip={currentRt}
                          currentPosition={index}
                          rtId={rtId}
                          setAssignedOrders={setAssignedOrders}
                          refreshOrders={requestOrderData}
                        />
                      </Menu>
                      <Tooltip title={t('roundtrip.change_position')} arrow>
                        <Action
                          ref={changePositionBtnRef}
                          onClick={(e) => {
                            e?.stopPropagation();
                            setAnchorEl_position(e.currentTarget);
                          }}
                        >
                          <HandPointing
                            size={13}
                            color={
                              !anchorEl_position
                                ? themes?.default?.iconColor
                                : themes?.default?.primary
                            }
                          />
                        </Action>
                      </Tooltip>
                      <Menu
                        open={!!anchorEl_position}
                        anchorEl={anchorEl_position}
                        onClose={(e: any) => {
                          e?.stopPropagation();
                          setAnchorEl_position(null);
                        }}
                        anchorOrigin={{
                          vertical: 'bottom',
                          horizontal: 'left',
                        }}
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'left',
                        }}
                      >
                        <ChangePositionPopover
                          setAnchorEl_position={setAnchorEl_position}
                          order={data}
                          currentRoundtrip={currentRt}
                          currentPosition={index}
                          rtId={rtId}
                          setAssignedOrders={setAssignedOrders}
                        />
                      </Menu>
                    </>
                  )}
                  <If condition={!disableEdit}>
                    <ActionsStop
                      leftPosition={false}
                      onDisplayOrder={onDisplayOrder}
                      typeEdit={!disableEdit}
                      stop={stop || data}
                      setSelectedStop={setSelectedStop}
                      setAddRoundTripOpen={setAddRoundTripOpen}
                      orders={orders}
                      setOrders={setOrders}
                      handleDelete={handleDelete}
                      setHover={setHover}
                      roundtrip={currentRt}
                    />
                  </If>
                </div>
              ) : null}
              <Menu
                open={!!anchorEl_}
                anchorEl={anchorEl_}
                onClose={handleAddRoundTripMenuClose}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'right',
                }}
              >
                <OrderAssignPopover
                  setAddRoundTripOpen={setAnchorEl_}
                  roundTripCopy={roundTripCopy}
                  order={data}
                  deleteStop={() => {
                    if (!roundTripCopy[index] && updateOrders) {
                      updateOrders();
                    }
                    deleteFunctionStop(
                      deleteStop,
                      roundTripCopy[index],
                      selectedStop,
                    );
                    requestOrderData();
                  }}
                  currentRoundtrip={currentRt}
                  accordionStatus={accordionStatus}
                  setAccordionStatus={setAccordionStatus}
                  toggleAssignToRoundtrip={toggleAssignToRoundtrip}
                  removeStopFromOrders={removeStopFromOrders}
                />
              </Menu>
            </CardFooter>
          </Data>
        )}
      </Draggable>
      <MapPopover
        open={mapOpen}
        handleClose={() => {
          setHover(false);
          handleCloseStopMap(setMapOpen);
        }}
        latitude={data?._deck?.location?.geometry?.coordinates[1]}
        longitude={data?._deck?.location?.geometry?.coordinates[0]}
        editMode={true}
      />
    </div>
  );
};

const Data = styled.div<{
  scrollWdth: number;
  checked: boolean;
  mapVersion?: boolean;
}>`
  user-select: none;
  background-color: ${(props) =>
    props.checked
      ? themes?.default?.bgChecked
      : themes?.default?.accordionWhiteBg};
  width: ${(props) => (props.mapVersion ? '284px' : '234px')};
  padding: 8px;
  z-index: 2000;
  border: 1px solid
    ${(props) =>
      props.checked
        ? themes?.default?.checkedBorder
        : themes?.default?.lightGrey3};
  border-radius: 8px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  gap: 12px;
  cursor: default;
  position: relative;

  .bottom_flex {
    display: flex;
    width: auto;
  }
  .client_name {
    overflow: overlay;
    font-size: 0.75rem;
    width: 166px;
    height: fit-content;
  }
  .data {
    display: flex;
    gap: 7px;
    width: max-content;
    max-height: 118px;
  }
  button {
    padding-top: 0px;
  }
  .gray_value {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3.06667px 3.83333px;
    width: fit-content;
    height: 23.13px;
    background: rgba(0, 0, 0, 0.04);
    border-radius: 3.06667px;
    font-size: 0.625rem;
    line-height: 17px;
    letter-spacing: 0.352667px;
  }
  .time {
    padding: 1px 10px;
    min-width: max-content;
    border: 1px solid ${themes?.default?.greyDisabled};
    border-radius: 16px;
    font-family: 'Roboto';
    font-style: normal;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 18px;
  }
  * {
    user-select: none;
  }
`;

const CardBody = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const CardInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 4px;

  label {
    height: 12px;
    font-size: 0.65rem;
    letter-spacing: 0.1599999964237213px;
    color: ${themes?.default?.buttonGreyText};
  }

  p {
    margin: 0;
    font-size: 0.75rem;
    font-weight: 400;
    letter-spacing: 0.17000000178813934px;
    color: ${themes?.default?.lightTextPrimary};
  }

  .clientCode {
    font-size: 0.75rem;
    font-weight: 700;
  }

  .orderCode {
    font-size: 0.625rem;
    font-weight: 500;
    letter-spacing: 0.1599999964237213px;
    color: ${themes?.default?.buttonGreyText};
  }

  .code {
    font-size: 0.625rem;
    font-weight: 700;
    letter-spacing: 0.1599999964237213px;
    color: ${themes?.default?.buttonGreyText};
    cursor: pointer;
    text-decoration: underline;
  }
`;

const CardTagsContainer = styled.div<{ scrollWdth; mapVersion }>`
  display: flex;
  flex-direction: column;
  gap: 7px;

  .container {
    overflow: hidden;
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    gap: 7px;
    flex-direction: row;
    width: 100%;
    transition: transform 0.7s;
  }
  .item {
    width: 50px;
    height: 50px;
    background-color: ${themes?.default?.veryLightGrey};
    display: flex;
    align-items: center;
    justify-content: center;
  }
`;

const CardFooter = styled.div`
  display: flex;
  justify-content: space-between;

  .cardStatus,
  .cardAction {
    display: flex;
    align-items: center;
    gap: 12px;
    min-height: 12px;
    max-height: 12px;
  }
`;

const Action = styled.div`
  width: fit-content;
  cursor: pointer;
`;
