import Toolbar from '@mui/material/Toolbar';
import { Alert, SxProps } from '@mui/material';
import { ColumnPopover } from 'app/components/TableComponent/ColumnPopover';
import SearchField from 'app/components/Search/SearchField';
import { NO_DATA_REGULAR } from 'app/components/const';
import { REGULAR } from 'app/components/TableComponent/constants';
import { clearAllAction } from 'functions/clearAllAction';
import { selectAllAction } from 'functions/selectAllAction';
import { changeColumnAction } from 'functions/changeColumnAction';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { themes } from 'styles/theme/themes';
import { useGrid } from '../../hooks';
import { VerticalDivider } from 'app/components/EnhancedTableToolbar/components/VerticalDivider';
import If from 'app/components/If';
import { RegularPageTitle } from 'app/components/TableComponent/helpers/grid';
import { ComponentsStack } from 'app/components/EnhancedTableToolbar/components/ComponentsStack';
import { AddIconButton } from 'app/components/AddIconButton';
import ExportButton from 'app/components/FilterComponents/components/ExportButton';
import { useModal } from 'app/components/Dialog/hooks';
import { useDisabledExport } from 'app/components/EnhancedTableToolbar/hooks/useDisabledExport';
import { GridFilters } from './components/GridFilters';
import { ActionIconButton } from 'app/components/ActionIconButton';
import { Icon } from 'app/components/Icon';
import { TrashSimple, UploadSimple } from '@phosphor-icons/react';
import { useDebounce } from 'common/hooks/useDebounce';
import React from 'react';

interface EnhancedTableToolbarProps {
  leftSlot?: JSX.Element;
  middleSlot?: JSX.Element;
  title?: string;
  disabled?: boolean;
  onAddClick?: (_e?: React.MouseEvent<HTMLButtonElement>) => void;
  onExportClick?: () => void;
  onBulkDelete?: (selected: string[]) => void;
  bulkExport?: boolean;
  bulkActions?: JSX.Element;
  filterComponent?: JSX.Element;
  actionsComponent?: JSX.Element;
  middleActionsComponent?: JSX.Element;
  sx?: SxProps;
}
// Hook

export const GridToolbar: React.FC<EnhancedTableToolbarProps> = ({
  title,
  disabled,
  leftSlot,
  middleSlot,
  onAddClick,
  onExportClick,
  filterComponent,
  onBulkDelete,
  bulkExport,
  bulkActions,
  actionsComponent,
  middleActionsComponent,
  sx,
}) => {
  const { t } = useTranslation();
  const { openModal, closeModal } = useModal();

  const {
    selected,
    search,
    setSearch,
    columns,
    setColumns,
    totalRows,
    setSelected,
  } = useGrid();

  const { disabledExport } = useDisabledExport({
    total: totalRows,
    numSelected: selected?.length,
  });

  const debouncedDisabled = useDebounce(disabled, 300);

  const onExportModal = () => {
    openModal({
      title: t(selected?.length ? 'export_selected' : 'export'),
      content: (
        <If
          condition={!disabledExport}
          otherwise={
            <Alert variant="standard" severity="error" sx={{ width: '100%' }}>
              {t('export_data_exceeds_max', { max: '25,000' })}
            </Alert>
          }
        >
          <Alert variant="standard" severity="info" sx={{ width: '100%' }}>
            {t('export_count_rows', {
              count: selected?.length || totalRows,
            })}
          </Alert>
        </If>
      ),
      action: {
        actionText: t('export'),
        actionCallback: () => {
          onExportClick?.();
          closeModal();
          setSelected([]);
        },
        buttonProps: { disabled: disabledExport },
      },
      cancel: true,
    });
  };

  return (
    <Toolbar
      sx={{
        pl: '1rem !important',
        pr: '1rem !important',
        background: 'white',
        minHeight: 'fit-content !important',
        borderBottom: `1px solid ${themes?.default?.lightFill}`,
        height: themes.default.toolBarContainerHeight,
        ...sx,
      }}
    >
      <ToolbarContainer>
        <RegularPageTitle>
          <span style={{ marginRight: '16px' }}>{t(title || '')}</span>{' '}
          {leftSlot || null}
        </RegularPageTitle>
        {middleSlot || null}
        <If condition={!selected?.length}>
          <ComponentsStack>
            {!!setSearch && (
              <SearchField
                value={search || ''}
                setValue={setSearch}
                disabled={disabled || false}
                textFieldProps={{
                  placeholder: t('common.place_holders.search'),
                  label: '',
                  margin: 'none',
                }}
              />
            )}

            {!!columns && (
              <ColumnPopover
                headCells={columns}
                setHeadCells={setColumns}
                changeColumn={(id, value) => {
                  changeColumnAction(id, value, columns, setColumns);
                }}
                clearAll={() => {
                  clearAllAction(columns, setColumns);
                }}
                selectAll={() => {
                  selectAllAction(columns, setColumns);
                }}
                name={disabled ? NO_DATA_REGULAR : REGULAR}
              />
            )}

            <If condition={!!middleActionsComponent}>
              <>
                <VerticalDivider />
                {middleActionsComponent}
              </>
            </If>

            {!!filterComponent && (
              <GridFilters
                filterComponent={filterComponent}
                disabled={disabled}
              />
            )}

            <If
              condition={!!actionsComponent || !!onExportClick || !!onAddClick}
            >
              <VerticalDivider />
            </If>

            <If condition={!!actionsComponent}>{actionsComponent}</If>
            <If condition={!!onExportClick}>
              <ExportButton
                onClick={() => onExportModal()}
                placeholder={t('common.export_all')}
                disabled={!!disabled || !totalRows}
              />
            </If>
            <If condition={!debouncedDisabled}>
              <If condition={!!onAddClick}>
                <AddIconButton onClick={() => onAddClick?.()} />
              </If>
            </If>
          </ComponentsStack>
        </If>

        <If condition={!!selected.length}>
          <ComponentsStack>
            {!!bulkActions && bulkActions}
            <If condition={!!bulkExport && !!onExportClick}>
              <ActionIconButton
                title="export"
                onClick={() => onExportModal?.()}
                icon={<UploadSimple />}
              />
            </If>
            <If condition={!!onBulkDelete}>
              <ActionIconButton
                title="delete"
                onClick={() => {
                  onBulkDelete?.(selected);
                  setSelected([]);
                }}
                icon={<Icon icon={<TrashSimple />} />}
              />
            </If>
          </ComponentsStack>
        </If>
      </ToolbarContainer>
    </Toolbar>
  );
};

const ToolbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: white;
  width: 100%;
  height: 38px;
`;
