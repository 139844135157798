import { useContext } from 'react';
import { ModalContext } from '../context';
import AddByFileForm from 'app/components/AddByFile/AddByFileForm';
import { useTranslation } from 'react-i18next';
import { AddByFileFormProps } from 'app/components/AddByFile/types';

export const useModal = () => {
  const context = useContext(ModalContext);
  if (context === undefined) {
    throw new Error(
      'Modal context dependency not provided, please add it to the root file of your project OR root of your page',
    );
  }

  return context;
};

/**
 * Used per module for importing data by file
 */
export const useAddByFileModal = () => {
  const { openModal } = useModal();
  const { t } = useTranslation();

  const open = ({
    onValidate,
    onImport,
    module,
    sampleFile,
    defaultWarehouse,
  }: AddByFileFormProps) => {
    openModal({
      title: t('add-by-file.dialog.title'),
      content: (
        <AddByFileForm
          onValidate={onValidate}
          onImport={onImport}
          module={module}
          sampleFile={sampleFile}
          defaultWarehouse={defaultWarehouse}
        />
      ),
      cancel: true,
      action: {
        actionText: t('common.buttons.proceed'),
        actionCallback() {},
        buttonProps: { disabled: true },
      },
    });
  };

  return { open };
};
